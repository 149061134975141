import { mutateUsers } from "@/api/users/useUsersQuery";
import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { DepartmentAutoComplete } from "@/features/ui/autoComplete/department/department-autoComplete";
import { EquipmentAutoComplete } from "@/features/ui/autoComplete/equipment/equipment-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import styled from "@emotion/styled";
import { Box, Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { MasterApiUsersUserIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const isDepartmentInformIncluded = customFunctions.ADD_USER_DEPARTMENT_INFORM;

export function UsersUpdateForm(params: { formatterProps: any }) {
  const { closeModal } = useModal();
  const { t } = useTranslation();
  const { formatterProps } = params;
  const queryClient = useQueryClient();
  const { mutate: updateMutate } = useMutation(
    (params: MasterApiUsersUserIdPutRequest) =>
      mutateUsers.update(params).mutationFn(params as MasterApiUsersUserIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users"]);
        closeModal(formatterProps);
        customAlert("사용자 수정에 성공하였습니다.", "수정 성공", "green");
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({ error, errorContext: "사용자 코드" }),
          "사용자 수정 실패",
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      code: formatterProps.code,
      name: formatterProps.name,
      username: formatterProps.username,
      password: formatterProps.password,
      equipmentCode: formatterProps.equipmentCode,
      printerCode: formatterProps.printerCode,
      fromLocationCode: formatterProps.fromLocationCode,
      toLocationCode: formatterProps.toLocationCode,
      ...(isDepartmentInformIncluded ? { departmentCode: formatterProps.departmentCode } : {}),
    },
  });

  const validatePassword = (password: string): boolean => {
    const minLength = 8;
    const hasLetter = /[a-zA-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return (
      password.length >= minLength &&
      ((hasLetter && hasNumber) || (hasLetter && hasSpecialChar) || (hasNumber && hasSpecialChar))
    );
  };

  const onSubmit = () => {
    const { password } = form.values;

    if (password && !validatePassword(password)) {
      customAlert(
        "비밀번호는 최소 8자리 이상이어야 하며, 문자, 숫자, 특수문자 중 두 종류 이상을 포함해야 합니다.",
        "유효성 검사 실패",
        "red",
      );
      return;
    }

    try {
      updateMutate({
        userId: formatterProps.id as number,
        usersUserIdDeleteRequest: {
          code: form.values.code!,
          name: form.values.name!,
          username: form.values.username!,
          password: form.values.password!,
          equipmentCode: form.values.equipmentCode!,
          printerCode: form.values.printerCode!,
          fromLocationCode: form.values.fromLocationCode!,
          toLocationCode: form.values.toLocationCode!,
          departmentCode: form.values.departmentCode!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: "사용자 코드" }),
        "사용자 수정 실패",
        "red",
      );
    }
  };

  return (
    <Box maw={320} mx="auto">
      <Form>
        <ModalFieldSet>
          <TextInput
            label="사용자코드"
            withAsterisk
            {...form.getInputProps("code")}
            defaultValue={formatterProps?.code}
            error={form.isDirty("code") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("code") ? "red !important" : "",
                borderColor: form.isDirty("code") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <TextInput
            label="사용자명"
            withAsterisk
            {...form.getInputProps("name")}
            defaultValue={formatterProps?.name}
            error={form.isDirty("name") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("name") ? "red !important" : "",
                borderColor: form.isDirty("name") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <TextInput
            label="로그인아이디"
            withAsterisk
            {...form.getInputProps("username")}
            defaultValue={formatterProps?.username}
            error={form.isDirty("username") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("username") ? "red !important" : "",
                borderColor: form.isDirty("username") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <TextInput
            // type="number"
            label="비밀번호"
            // placeholder="password"
            {...form.getInputProps("password")}
            defaultValue={formatterProps?.password}
            error={
              form.isDirty("password") && form.values.password !== undefined
                ? validatePassword(form.values.password)
                  ? t("common.toSave")
                  : t("common.passwordRule")
                : ""
            }
            styles={{
              input: {
                color: form.isDirty("password") ? "red !important" : "",
                borderColor: form.isDirty("password") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <EquipmentAutoComplete
            label="설비코드"
            {...form.getInputProps("equipmentCode")}
            defaultValue={formatterProps?.equipmentCode}
            error={form.isDirty("equipmentCode") ? "저장 필요" : ""}
          />
          <LocationAutoComplete
            label="로케이션코드"
            {...form.getInputProps("fromLocationCode")}
            defaultValue={formatterProps?.fromLocationCode}
            error={form.isDirty("fromLocationCode") ? "저장 필요" : ""}
          />
          <LocationAutoComplete
            label="로케이션코드"
            {...form.getInputProps("toLocationCode")}
            defaultValue={formatterProps?.toLocationCode}
            error={form.isDirty("toLocationCode") ? "저장 필요" : ""}
          />
          {isDepartmentInformIncluded && (
            <DepartmentAutoComplete
              label="부서코드"
              {...form.getInputProps("departmentCode")}
              defaultValue={formatterProps?.departmentCode}
              error={form.isDirty("departmentCode") ? "저장 필요" : ""}
            />
          )}
          <TextInput
            label="프린터 코드"
            {...form.getInputProps("printerCode")}
            defaultValue={formatterProps?.printerCode}
            error={form.isDirty("printerCode") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("printerCode") ? "red !important" : "",
                borderColor: form.isDirty("printerCode") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              취소
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "users",
                values: form.values,
              })}
              onClick={onSubmit}
            >
              사용자 수정
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
}

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
