import DefaultInstance, { MasterInstance } from "@/instance/axios";
import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from "@lukemorales/query-key-factory";
import {
  DefaultApiItemGradeViewGetRequest,
  MasterApiItemsGetRequest,
  MasterApiItemsItemIdDeleteRequest,
  MasterApiItemsItemIdGetRequest,
  MasterApiItemsItemIdPutRequest,
  MasterApiItemsOneStepCreatePostRequest,
  MasterApiItemsPostRequest,
  MasterApiItemsRemoveAllDeleteRequest,
  MasterApiItemsValidatePostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";

export const items = createQueryKeys("items", {
  all: null,
  get: (params: MasterApiItemsGetRequest) => {
    // const queryParams = { ...params };
    const queryParams = {
      ...params,
      query: JSON.stringify({ $and: params?.query }),
      sort: params.sort?.length ? params.sort : "-id",
    }; // 품목 조회 시 기본 정렬 표시

    return {
      queryKey: [queryParams, JSON.stringify(queryParams)],
      queryFn: () => MasterInstance.itemsGet(queryParams),
    };
  },
  detail: (params: MasterApiItemsItemIdGetRequest) => ({
    queryKey: [params.itemId],
    queryFn: () => MasterInstance.itemsItemIdGet(params),
  }),
  typeGet: (params: MasterApiItemsGetRequest) => {
    const query = { ...params }; // params 객체를 복사합니다.
    if (params && params.query) {
      query["query"] = JSON.stringify(params.query);
    }

    if (params.query.itemType === "PRODUCT" || params.query.itemType === "SEMI_PRODUCT") {
      query["populate"] = ["lots", "routings"];
    } else {
      query["populate"] = ["lots"];
    }

    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => MasterInstance.itemsGet(query),
    };
  },
  codeSearch: (params?: MasterApiItemsGetRequest) => {
    const query = { ...params }; // params 객체를 복사합니다.
    if (params && params.query) {
      query["query"] = JSON.stringify(params.query);
    }

    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => MasterInstance.itemsGet(query),
    };
  },
  getView: (params: DefaultApiItemGradeViewGetRequest) => {
    // const queryParams = { ...params };
    const queryParams = {
      ...params,
      query: JSON.stringify({ $and: params?.query }),
      sort: params.sort?.length ? params.sort : "-id",
    }; // 품목 View 조회 시 기본 정렬 표시

    return {
      queryKey: [queryParams, JSON.stringify(queryParams)],
      queryFn: () => DefaultInstance.itemGradeViewGet(queryParams),
    };
  },
  // find: (params: MasterApiItemsFindPostRequest) => {
  //     const query = { ...params }; // params 객체를 복사합니다.

  //     if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
  //         query["query"] = JSON.stringify(params.query);
  //     }
  //     // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
  //     if (params.search) {
  //         query["search"] = params.search;
  //         query["searchFields"] = params.searchFields;
  //     }
  //     return {
  //         queryKey: [query, JSON.stringify(query.query)],
  //         queryFn: () => MasterInstance.itemsFindPost(query),
  //     }
  // },
});

export const mutateItems = createMutationKeys("items", {
  create: (params: MasterApiItemsPostRequest) => ({
    mutationKey: [params.itemsGetRequest],
    mutationFn: () => MasterInstance.itemsPost(params),
  }),
  oneStep: (params: MasterApiItemsOneStepCreatePostRequest) => ({
    mutationKey: [params.itemsOneStepCreatePostRequest],
    mutationFn: () => MasterInstance.itemsOneStepCreatePost(params),
  }),
  update: (params: MasterApiItemsItemIdPutRequest) => ({
    mutationKey: [params.itemId, params.itemsGetRequest],
    mutationFn: () => MasterInstance.itemsItemIdPut(params),
  }),
  delete: (params: MasterApiItemsItemIdDeleteRequest) => ({
    mutationKey: [params.itemId],
    mutationFn: () => MasterInstance.itemsItemIdDelete(params),
  }),
  deleteAll: (prarams: MasterApiItemsRemoveAllDeleteRequest) => ({
    mutationKey: [prarams.id],
    mutationFn: () => MasterInstance.itemsRemoveAllDelete(prarams),
  }),
  validate: (params: MasterApiItemsValidatePostRequest) => ({
    mutationKey: [params.itemsValidatePostRequest],
    mutationFn: () => MasterInstance.itemsValidatePost(params),
  }),
});

export const itemsQueryKeys = mergeQueryKeys(items, mutateItems);
