import { MonitoringDataProps } from "@/features/monitoring";
import { MonitoringFieldConfig } from "@/features/monitoring/components/parentsBox/Monitoring";
import SeconeTimeUtil from "@/utils/secondTimeUtil";
import { setFilteringDecimalPoint, setToLocaleString } from "@/utils/unitMark";
import { t } from "i18next";

const textCommonFields = (data: MonitoringDataProps) => [
  { label: "기계명", value: data?.equipmentName ?? "-" },
  { label: "Lot 번호", value: data?.lotId ?? "-" },
];

const textItemFields = (data: MonitoringDataProps) => [
  { label: "품목명", value: data?.itemWithGrade?.name ?? "-" },
  { label: "품목군", value: t(data?.itemWithGrade?.itemType ?? "") ?? "-" },
  { label: "Grade", value: data?.itemWithGrade?.gradeName ?? "-" },
  { label: "Color", value: data?.itemWithGrade?.itemCateogryName ?? "-" },
  { label: "형태", value: data?.itemWithGrade?.abstractItemCode ?? "-" },
];

const textProductionFields = (data: MonitoringDataProps) => [
  { label: "생산 수량", value: setToLocaleString(data?.quantity) ?? "0", highlightKey: true },
  { label: "계획 수량", value: setToLocaleString(data?.targetQuantity) ?? "0", highlightKey: true },
];

const textForgingFields = (data: MonitoringDataProps) => [
  { label: "P넘버", value: data?.influxEquipmentCode ?? "-" },
];

const textDefaultFields = (data: MonitoringDataProps) => [
  { label: "P넘버", value: data?.influxEquipmentCode ?? "-" },
  { label: "품목코드", value: data?.itemCode ?? "-" },
  { label: "생산품목", value: data?.itemName ?? "-" },
  { label: "규격", value: data?.itemSpec ?? "-" },
  { label: "담당자", value: data?.worker ?? "-" },
];

export function getTextFields(data: MonitoringDataProps): MonitoringFieldConfig[] {
  switch (data?.equipmentType) {
    case "FORMING_01":
    case "FORMING_02":
      return [...textCommonFields(data), ...textProductionFields(data), ...textItemFields(data)];
    case "PACKING":
      return [...textCommonFields(data), ...textItemFields(data)];
    case "FORGING":
      return [...textCommonFields(data), ...textForgingFields(data)];
    default:
      return [...textCommonFields(data), ...textDefaultFields(data)];
  }
}
const upperForming01Fields = (data: MonitoringDataProps) => [
  { label: "메인모터 RPM", value: data?.mainMotorRpm ?? "0", highlightKey: true },
  { label: "메인피더 RPM", value: data?.mainFeederRpm ?? "0", highlightKey: true },
  { label: "사이드피더 RPM", value: data?.sideFeederRpm ?? "0", highlightKey: true },
  { label: "VOL피더 RPM", value: data?.volFeederRpm ?? "0", highlightKey: true },
];

const upperForming02Fields = (data: MonitoringDataProps) => [
  { label: "메인피더 RPM", value: data?.mainFeederRpm ?? "0", highlightKey: true },
  { label: "스크류 RPM", value: data?.screwRpm ?? "0", highlightKey: true },
];

const upperPackingFields = (data: MonitoringDataProps) => [
  { label: "포장단위", value: data?.kgPerCount ?? "0", highlightKey: true, unit: "Kg" },
  {
    label: "포장횟수",
    value: setToLocaleString(data?.packingCounter) ?? "0",
    highlightKey: true,
  },
];

const upperForgingFields = (data: MonitoringDataProps) => [
  { label: "목표수량", value: setToLocaleString(data?.targetQuantity) ?? "0", highlightKey: true },
  { label: "작업수량", value: setToLocaleString(data?.quantity) ?? "0", highlightKey: true },
];

const upperDefaultFields = (data: MonitoringDataProps) => [
  { label: "SPM", value: data?.spm ?? "0", highlightKey: true },
  {
    label: "프리셋 카운터",
    value: `${setToLocaleString(data?.presetCounter)} / ${setToLocaleString(data?.limitCounter)}`,
    highlightKey: true,
  },
  {
    label: "종합 카운터",
    value: setToLocaleString(data?.totalCounter) ?? "0",
    highlightKey: true,
  },
  { label: "기계 가동시간", value: data?.runTime ?? "0", highlightKey: true },
  { label: "기계 비가동시간", value: data?.idleTime ?? "00:00:00", highlightKey: true },
  {
    label: "기계 가동률",
    value: setFilteringDecimalPoint(data?.runTimeRatio, 2),
    unit: "%",
    highlightKey: true,
  },
];

export function getUpperFields(data: MonitoringDataProps): MonitoringFieldConfig[] {
  switch (data?.equipmentType) {
    case "FORMING_01":
      return upperForming01Fields(data);
    case "FORMING_02":
      return upperForming02Fields(data);
    case "PACKING":
      return upperPackingFields(data);
    case "FORGING":
      return upperForgingFields(data);
    default:
      return upperDefaultFields(data);
  }
}

const lowerFormingFields = (data: MonitoringDataProps) => [
  { label: "진공 RPM", value: data?.vacuumRpm ?? "-", highlightKey: true },
  { label: "진공 압력", value: data?.vacuumPressure ?? "-", highlightKey: true },
  { label: "수지 온도", value: data?.meltTemperature ?? "-", highlightKey: true, unit: "℃" },
  { label: "수지 압력", value: data?.meltPressure ?? "-", highlightKey: true },
];

const lowerPackingFields = (data: MonitoringDataProps) => [
  {
    label: "종합카운터",
    value: setToLocaleString(data?.totalPackingCounter) ?? "0",
    highlightKey: true,
  },
  {
    label: "생산수량(작업지시서)",
    value: `${setToLocaleString(data?.quantity ?? "0")}`,
    highlightKey: true,
  },
  {
    label: "목표수량",
    value: `${setToLocaleString(data?.targetQuantity ?? "0")}`,
    highlightKey: true,
  },
  {
    label: "작업 진행률",
    value: data?.productionRate ?? "0",
    highlightKey: true,
  },
];

const lowerForgingFields = (data: MonitoringDataProps) => [
  { label: "카운터", value: setToLocaleString(data?.presetCounter) ?? "0", highlightKey: true },
];

const lowerDefaultFields = (data: MonitoringDataProps) => [
  { label: "금형코드", value: data?.moldCode ?? "-" },
  {
    label: "생산남은 시간",
    value: data?.remainTime === "-1" ? "∞" : SeconeTimeUtil(data?.remainTime),
    highlightKey: true,
  },
  { label: "키캠상태", value: data?.keyCamStatus ?? "OFF", highlightKey: true },
  {
    label: "생산수량",
    value: setToLocaleString(data?.presetCounter) ?? "0",
    highlightKey: true,
  },
  {
    label: "부하율",
    value: `${setFilteringDecimalPoint(data?.loadRatio)} % \n${data?.numeratorLoad ?? "0"} / ${data?.denominatorLoad ?? "0"}`,
    highlightKey: true,
    backgroundColor: "gray",
    color: "white",
  },
  { label: "캐비티", value: data?.moldCavity ?? "0" },
];

export function getLowerFields(data: MonitoringDataProps): MonitoringFieldConfig[] {
  switch (data?.equipmentType) {
    case "FORMING_01":
    case "FORMING_02":
      return lowerFormingFields(data);
    case "PACKING":
      return lowerPackingFields(data);
    case "FORGING":
      return lowerForgingFields(data);
    default:
      return lowerDefaultFields(data);
  }
}

const upperWorkStatusFields = (data: MonitoringDataProps) => [
  { label: "작업상태", value: t(data?.trackingStatus ?? "") ?? "OFF", highlightKey: true },
];

export function getWorkStatusFields(data: MonitoringDataProps): MonitoringFieldConfig[] {
  return upperWorkStatusFields(data);
}
