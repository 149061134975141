import XLSX from "xlsx-js-style";
import { getColumnWidths } from "./excelDownLoad";

// 각 row의 데이터를 담을 때 사용하는 타입 정의
type rowData = {
  v: string | number; // 셀에 표시될 값 (header 또는 row의 실제 내용)
  t: string; // 셀의 데이터 타입 (s: 문자열, n: 숫자, b: 불리언, d: 날짜, e: 에러)
  s: any; // 셀의 스타일 설정 (headerStyle이나 dataStyle 같은 커스텀 스타일 적용 가능)
  /*
      스타일을 커스터마이징할 때는 아래와 같은 예시로 진행 가능
      s: {
          ...dataStyle,
          numFmt: 'yyyy-mm-dd [$-ko-KR]AM/PM hh:mm' // 셀의 날짜 형식 (한국 표준시 기준, 오전/오후 표기)
      }
      */
};

type ExcelSheetData = {
  sheetName: string; // 🔥 시트 이름
  headerRow: rowData[]; // 해당 시트의 헤더 데이터
  dataRows: rowData[][]; // 해당 시트의 데이터
};

type MultiSheetExcelData = {
  sheets: ExcelSheetData[]; // 🔥 여러 시트를 담을 배열
  fileName: string; // 다운로드할 파일 이름
};

export const multiSheetExcelDownload = async ({ sheets, fileName }: MultiSheetExcelData) => {
  const wb = XLSX.utils.book_new();

  sheets.forEach(({ sheetName, headerRow, dataRows }) => {
    const rows = [headerRow, ...dataRows];

    // 동적 열 너비 설정
    const dynamicColWidths = getColumnWidths(headerRow, dataRows);
    const cols = dynamicColWidths.map((width: number) => ({ wpx: width }));

    const ws = XLSX.utils.aoa_to_sheet(rows);
    ws["!cols"] = cols;

    // 🔥 각 시트마다 개별 시트 추가
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
  });

  XLSX.writeFile(wb, `${fileName}.xlsx`);
};
