import { inventories } from "@/api/inventories/useInventoriesQuery";
import { LocationDetailInForm } from "@/features/location/detail/LocationDetailInForm";
import { Main } from "@/features/standard/Main";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Flex, Tabs, Text, Title } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { LocationLedgerTabs } from "../tabs/locationLedgerTabs";

const Detail = Object.assign(Main, {
  Container: DetailContainer,
  Wrapper: DetailWrapper,
  Content: DetailContent,
});

interface DetailLocationProps {
  locationCode: string | undefined;
}

export const LocationDetailForm = (params: DetailLocationProps) => {
  const { locationCode } = params;

  const { data } = useQuery(
    inventories.find({
      query: { $and: [{ locationCode: { $eq: locationCode } }] },
      populate: ["lot", "item"],
    }),
  );

  return (
    <>
      <Flex w="85rem" style={{ overflow: "auto" }}>
        <Detail.Wrapper>
          <Detail.Content>
            <LocationDetailInForm data={data} />
            <Tabs defaultValue="locationLedger">
              <LeftSpace />
              <Tabs.List>
                <Tabs.Tab value="locationLedger">재고 입출고 기록</Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value="locationLedger" p={20}>
                <Title order={3}>재고 입출고 기록</Title>
                <Text>우리회사에서 다루는 재고 입출고 기록에 대한 정보를 보여줍니다.</Text>
                <LocationLedgerTabs locationCode={locationCode} />
              </Tabs.Panel>
            </Tabs>
          </Detail.Content>
        </Detail.Wrapper>
      </Flex>
    </>
  );
};

export const LeftSpace = styled.div`
  width: 32px;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;
