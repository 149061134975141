import { WorkBox } from "@/features/monitoring/components/childrenBox/WorkBox";
import { WorkBoxTitle } from "@/features/monitoring/components/childrenBox/WorkBoxTitle";
import { WorkStatusBox } from "@/features/monitoring/components/childrenBox/WorkStatusBox";
import { WorkTextBox } from "@/features/monitoring/components/childrenBox/WorkTextBox";
import { WorkValueText } from "@/features/monitoring/components/childrenBox/WorkValueText";
import { Parents } from "@/features/monitoring/components/parentsBox/Parents";
import { ParentsBox } from "@/features/monitoring/components/parentsBox/ParentsBox";
import { ParentsBoxDiv } from "@/features/monitoring/components/parentsBox/ParentsBoxDiv";
import { ParentsBoxText } from "@/features/monitoring/components/parentsBox/ParentsBoxText";
import { ParentsBoxTextDiv } from "@/features/monitoring/components/parentsBox/ParentsBoxTextDiv";
import { Main } from "@/features/standard/Main";
import { EquipmentStatusColor } from "@/utils/equipmentStatus";
import { Box, Flex, Text } from "@mantine/core";
import { useEffect, useState } from "react";

const Monitoring = Object.assign({}, Main, {
  Parents: Parents,
  ParentsBox: ParentsBox,
  ParentsBoxDiv: ParentsBoxDiv,
  ParentsBoxTextDiv: ParentsBoxTextDiv,
  ParentsBoxText: ParentsBoxText,
  WorkStatusBox: WorkStatusBox,
  WorkBox: WorkBox,
  WorkTextBox: WorkTextBox,
  WorkText: WorkBoxTitle,
  Box: Box,
  Flex: Flex,
  WorkValueText: WorkValueText,
});

export interface MonitoringFieldConfig {
  label: string;
  value?: string | number | undefined;
  unit?: string;
  highlightKey?: boolean;
  color?: string;
  backgroundColor?: string;
}

interface MonitoringBoxConfig {
  statusInfo?: {
    pressStatus: string | undefined;
    errorName: string | undefined;
  };
  textFields?: MonitoringFieldConfig[];
  upperFields?: MonitoringFieldConfig[];
  lowerFields?: MonitoringFieldConfig[];
  workStatusFields?: MonitoringFieldConfig[];
}

export const MonitoringBox = (params: MonitoringBoxConfig) => {
  const { textFields, statusInfo, upperFields, lowerFields, workStatusFields } = params;

  const [prevData, setPrevData] = useState<MonitoringFieldConfig[]>([]);
  const [highlight, setHighlight] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const newHighlight: { [key: string]: boolean } = {};

    if (!textFields || !upperFields || !lowerFields || !workStatusFields) return;

    [...textFields, ...upperFields, ...lowerFields, ...workStatusFields].forEach((field) => {
      const prevField = prevData.find((prev) => prev.label === field.label);
      if (prevField && prevField.value !== field.value) {
        newHighlight[field.label] = true;
      }
    });

    setHighlight(newHighlight);
    setPrevData([...textFields, ...upperFields, ...lowerFields, ...workStatusFields]);

    const timeout = setTimeout(() => {
      setHighlight((prev) => {
        const updatedHighlight = { ...prev };
        Object.keys(updatedHighlight).forEach((key) => {
          updatedHighlight[key] = false;
        });
        return updatedHighlight;
      });
    }, 1000);

    return () => clearTimeout(timeout);
  }, [textFields, upperFields, lowerFields, workStatusFields]);

  const quantity = lowerFields?.find((field) => field.label === "생산수량(작업지시서)")?.value;
  const targetQuantity = lowerFields?.find((field) => field.label === "목표수량")?.value;

  // if (quantity && targetQuantity) {
  //   if (quantity <= targetQuantity) {
  //     alert("생산수량이 목표수량과 같거나 큽니다.");
  //   }
  // }
  return (
    <>
      <Monitoring.ParentsBox>
        <Monitoring.ParentsBoxDiv height="35%">
          {textFields?.map((field, index) => (
            <Monitoring.ParentsBoxTextDiv key={index}>
              <Monitoring.ParentsBoxText>
                {field.label} : {field.value}
              </Monitoring.ParentsBoxText>
            </Monitoring.ParentsBoxTextDiv>
          ))}
        </Monitoring.ParentsBoxDiv>
        <Monitoring.ParentsBoxDiv height="65%">
          <Flex gap={"0.5rem"} w={"100%"}>
            {statusInfo?.pressStatus && (
              <div style={{ marginTop: "0.5rem" }}>
                <Monitoring.WorkStatusBox
                  backgroundColor={EquipmentStatusColor[statusInfo?.pressStatus as string]}
                >
                  {statusInfo.pressStatus}
                </Monitoring.WorkStatusBox>
              </div>
            )}
            <Monitoring.Flex w={"100%"} direction={"row"} gap="0.5rem" h={"100%"}>
              <Flex w={"15%"} h={"100%"}>
                <Monitoring.WorkBox>
                  {workStatusFields?.map((field, index) => (
                    <Monitoring.WorkStatusBox key={index}>
                      <Monitoring.WorkText>{field.label}</Monitoring.WorkText>
                      <Monitoring.WorkValueText>
                        {field.value} {field.unit}
                      </Monitoring.WorkValueText>
                    </Monitoring.WorkStatusBox>
                  ))}
                </Monitoring.WorkBox>
              </Flex>
              <Flex w={"85%"} direction={"column"}>
                <Monitoring.WorkBox>
                  {upperFields?.map((field, index) => (
                    <Monitoring.WorkTextBox key={index} highlight={highlight[field.label]}>
                      <Monitoring.WorkText>{field.label}</Monitoring.WorkText>
                      <Monitoring.WorkValueText>
                        {field.value} {field.unit}
                      </Monitoring.WorkValueText>
                    </Monitoring.WorkTextBox>
                  ))}
                </Monitoring.WorkBox>
                <Monitoring.WorkBox>
                  {lowerFields?.map((field, index) => (
                    <Monitoring.WorkTextBox
                      backgroundColor={field?.backgroundColor}
                      key={index}
                      highlight={highlight[field.label]}
                    >
                      <Monitoring.WorkText color={field?.color}>{field.label}</Monitoring.WorkText>
                      <Monitoring.WorkValueText color={field?.color}>
                        {field.value} {field.unit}
                      </Monitoring.WorkValueText>
                    </Monitoring.WorkTextBox>
                  ))}
                </Monitoring.WorkBox>
              </Flex>
            </Monitoring.Flex>
          </Flex>
        </Monitoring.ParentsBoxDiv>
        {statusInfo?.pressStatus === "에러" ? (
          <Text c={"red"} weight={900}>
            에러명: {statusInfo.errorName}
          </Text>
        ) : null}
      </Monitoring.ParentsBox>
    </>
  );
};
