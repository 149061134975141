import { consumeTypeColor, routingTypeColor } from "@/constants/routings";
import { AbstractItemDetailForm } from "@/features/abstractItem/form/detailForm";
import { GradeDetailForm } from "@/features/grade/components/form/GradeDetailForm";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { ItemCategoryDetailForm } from "@/features/itemCategory/form/DetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { MoldDetailForm } from "@/features/mold/form/moldDetailForm";
import { OperationDetailForm } from "@/features/operations/components/form/operationDetailForm";
import { BadgeBox } from "@/features/standard/badge/BadgeBox";
import { BadgeContainer } from "@/features/standard/badge/BadgeContainer";
import { BadgeIcon } from "@/features/standard/badge/BadgeIcon";
import { BadgeMain } from "@/features/standard/badge/BadgeMain";
import { BadgeQuantity } from "@/features/standard/badge/BadgeQuantity";
import { BadgeSmallBox } from "@/features/standard/badge/BadgeSmallBox";
import { BadgeTextBox } from "@/features/standard/badge/BadgeTextBox";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import { theme } from "@/styles/theme";
import { Flex, Space, Text } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerRoutingsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconBuildingWarehouse } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { RoutingDetailForm } from "../form/RoutingDetailForm";

const DetailRouting = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  Content: DetailContent,
});

const Badge = Object.assign(BadgeMain, {
  Box: BadgeBox,
  SmallBox: BadgeSmallBox,
  Quantity: BadgeQuantity,
  Icon: BadgeIcon,
  Text: Text,
  TextBox: BadgeTextBox,
  Container: BadgeContainer,
});
interface DetailRoutingInformProps {
  data: ItemsGet200ResponseRowsInnerRoutingsInner | undefined;
}

export const RoutingInform = (params: DetailRoutingInformProps) => {
  const { data } = params;
  const { t } = useTranslation();
  const { openModal } = useModal();

  const MaxSeq = data?.routingBoms && Math.max(...data.routingBoms.map((bom) => bom.seq ?? 0));

  return (
    <>
      <Badge.Container>
        <Badge.Box>
          <Badge.SmallBox>
            <Badge.Icon>
              <IconBuildingWarehouse size={26} color={theme?.colors?.violet?.[6]} />
            </Badge.Icon>
            <Badge.TextBox>
              <Badge.Quantity fz="lg" color="violet">
                {data?.routingBoms && data?.routingBoms[0]?.seq} /{" "}
                {MaxSeq === -Infinity ? "" : MaxSeq}
              </Badge.Quantity>
              <Badge.Text fz="xs" color={theme.colors?.gray?.[6]}>
                공정도순서
              </Badge.Text>
            </Badge.TextBox>
          </Badge.SmallBox>
        </Badge.Box>
      </Badge.Container>
      <DetailRouting>
        <DetailRouting.Title>{`[공정도] ${data?.code}`}</DetailRouting.Title>
        <DetailRouting.Inform>
          {
            "공정도 정보 : 작업지시의 공정도보는 작업지시를 생선한 시점의 공정도 정보를 스냅샷하여 사용합니다."
          }
        </DetailRouting.Inform>
        <Space h={8} />
        <DetailRouting.Field>
          <DetailRouting.Label>{"품목"}</DetailRouting.Label>
          <Flex gap="xs" align="center">
            <DetailRouting.Badge>{t(data?.itemWithGrade?.itemType as string)}</DetailRouting.Badge>
            <DetailRouting.Link
              onClick={() => {
                openModal(<ItemsDetailForm itemCode={data?.itemCode ?? ""} />, null, "");
              }}
            >
              {data?.itemWithGrade?.name}
            </DetailRouting.Link>
          </Flex>
        </DetailRouting.Field>
        <DetailRouting.Field>
          <DetailRouting.Label>Grade</DetailRouting.Label>
          <DetailRouting.Link
            onClick={() => {
              openModal(<GradeDetailForm gradeCode={data?.itemWithGrade?.gradeCode} />, null, "");
            }}
          >
            {data?.itemWithGrade?.gradeName}
          </DetailRouting.Link>
        </DetailRouting.Field>
        <DetailRouting.Field>
          <DetailRouting.Label>Color</DetailRouting.Label>
          <DetailRouting.Link
            onClick={() => {
              openModal(
                <ItemCategoryDetailForm ItemCategoryName={data?.itemWithGrade?.itemCategoryName} />,
                null,
                "",
              );
            }}
          >
            {data?.itemWithGrade?.itemCategoryName}
          </DetailRouting.Link>
        </DetailRouting.Field>
        <DetailRouting.Field>
          <DetailRouting.Label>형태</DetailRouting.Label>
          <DetailRouting.Link
            onClick={() => {
              openModal(
                <AbstractItemDetailForm AbstractItemCode={data?.itemWithGrade?.abstractItemCode} />,
                null,
                "",
              );
            }}
          >
            {data?.itemWithGrade?.abstractItemCode}
          </DetailRouting.Link>
        </DetailRouting.Field>

        <DetailRouting.Field>
          <DetailRouting.Label>{"공정도 시퀀스"}</DetailRouting.Label>
          <DetailRouting.Content>{data?.seq}</DetailRouting.Content>
        </DetailRouting.Field>
        <DetailRouting.Field>
          <DetailRouting.Label>{"공정도코드"}</DetailRouting.Label>
          <DetailRouting.Link
            onClick={() => openModal(<RoutingDetailForm routingCode={data?.code} />, null, "")}
          >
            {data?.code}
          </DetailRouting.Link>
        </DetailRouting.Field>
        <DetailRouting.Field>
          <DetailRouting.Label>{"공정"}</DetailRouting.Label>
          <DetailRouting.LinkWrapper>
            <DetailRouting.Badge>{"공정"}</DetailRouting.Badge>
            <DetailRouting.Link
              onClick={() =>
                openModal(<OperationDetailForm operationCode={data?.operationCode} />, null, "")
              }
            >
              {data?.operationCode}
            </DetailRouting.Link>
          </DetailRouting.LinkWrapper>
        </DetailRouting.Field>
        <DetailRouting.Field>
          <DetailRouting.Label>{"사용 금형"}</DetailRouting.Label>
          <DetailRouting.LinkWrapper>
            <DetailRouting.Badge>{"금형"}</DetailRouting.Badge>
            <DetailRouting.Link
              onClick={() => openModal(<MoldDetailForm moldCode={data?.moldCode} />, null, "")}
            >
              {data?.moldCode}
            </DetailRouting.Link>
          </DetailRouting.LinkWrapper>
        </DetailRouting.Field>
        <DetailRouting.Field>
          <DetailRouting.Label>{"BOM 소모 시점"}</DetailRouting.Label>
          <DetailRouting.Content>
            <DetailRouting.Badge color={consumeTypeColor[data?.consumeType!]}>
              {t(data?.consumeType!)}
            </DetailRouting.Badge>
          </DetailRouting.Content>
        </DetailRouting.Field>
        <DetailRouting.Field>
          <DetailRouting.Label color={routingTypeColor[data?.routingType!]}>
            {"공정도 타입"}
          </DetailRouting.Label>
          <DetailRouting.Content>
            <DetailRouting.Badge>{t(data?.routingType!)}</DetailRouting.Badge>
          </DetailRouting.Content>
        </DetailRouting.Field>
        <DetailRouting.Field>
          <DetailRouting.Label>{"생성정보"}</DetailRouting.Label>
          <DetailRouting.Link
            onClick={() =>
              openModal(<UsersDetailForm UserCode={data?.creatorUserCode} />, null, "")
            }
          >
            {data?.creatorUserCode}
            {dayjs(data?.createdAt).format("YYYY-MM-DD HH:mm:ss")}
          </DetailRouting.Link>
        </DetailRouting.Field>
        <DetailRouting.Field>
          <DetailRouting.Label>{"수정정보"}</DetailRouting.Label>
          <DetailRouting.Link
            onClick={() =>
              openModal(<UsersDetailForm UserCode={data?.updaterUserCode} />, null, "")
            }
          >
            {data?.updaterUserCode}
            {dayjs(data?.updatedAt).format("YYYY-MM-DD HH:mm:ss")}
          </DetailRouting.Link>
        </DetailRouting.Field>
      </DetailRouting>
    </>
  );
};
