import { mutateTransportation } from "@/api/transportation/useTransportationQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { PartnerAutoComplete } from "@/features/ui/autoComplete/partner/partner-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, Flex, Select, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  ScmApiTransportationTransportationIdPutRequest,
  TransportationGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const Update = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Text: Text,
  Flex: Flex,
  DateInput: DateInput,
});

interface TransportationUpdateFormProps {
  orderId?: number;
  formatterProps: TransportationGet200ResponseRowsInner | undefined;
}

export const TransportationUpdateForm = (props: TransportationUpdateFormProps) => {
  const { orderId, formatterProps } = props;
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { mutate: updateMutate } = useMutation(
    (params: ScmApiTransportationTransportationIdPutRequest) =>
      mutateTransportation
        .update(params)
        .mutationFn(params as ScmApiTransportationTransportationIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["transportation"]);
        closeModal(form.values);
        customAlert("배차 수정에 성공하였습니다.", "등록 성공", "green");
      },
      onError: (error: any) => {
        customAlert(handleErrorResponse({ error, errorContext: "배차 " }), "배차 수정 실패", "red");
      },
    },
  );

  const form = useForm({
    initialValues: {
      partnerCode: formatterProps?.partnerCode,
      code: formatterProps?.code,
      direction: formatterProps?.direction,
      status: formatterProps?.status,
      scheduledAt: formatterProps?.scheduledAt ? new Date(formatterProps.scheduledAt) : undefined,
      additional: formatterProps?.additional,
    },
  });

  const onSubmit = async () => {
    try {
      updateMutate({
        transportationId: formatterProps?.id ?? 0,
        transportationGetRequest: {
          partnerCode: form.values.partnerCode!,
          code: form.values.code!,
          direction: form.values.direction!,
          status: form.values.status!,
          scheduledAt: form.values.scheduledAt?.toDateString()!,
          additional: form.values.additional,
        },
      });
    } catch (error) {
      customAlert(handleErrorResponse({ error, errorContext: "배차 " }), "배차 수정 실패", "red");
    }
  };

  return (
    <>
      <Update.Wrapper>
        <PartnerAutoComplete
          label="협력사"
          placeholder="협력사"
          {...form.getInputProps("partnerCode")}
          withAsterisk
          error={form.isDirty("partnerCode") ? "저장 필요" : ""}
        />
        <TextInput
          label="배차코드"
          placeholder="배차코드"
          {...form.getInputProps("code")}
          withAsterisk
          error={form.isDirty("code") ? "저장 필요" : ""}
        />
        <Select
          label="방향"
          placeholder="방향"
          data={[
            { label: "수주", value: "INBOUND" },
            { label: "발주", value: "OUTBOUND" },
          ]}
          {...form.getInputProps("direction")}
          defaultValue={formatterProps?.direction}
          error={form.isDirty("direction") ? "저장 필요" : ""}
          styles={{
            input: {
              color: form.isDirty("direction") ? "red !important" : "",
              borderColor: form.isDirty("direction") ? "red !important" : "",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
        <Select
          label="상태"
          placeholder="상태"
          data={[
            { label: "예약확인중", value: "SCHEDULED" },
            { label: "지연중", value: "LOADING" },
            { label: "완료", value: "DONE" },
            { label: "운행중", value: "ON_ROAD" },
            { label: "사고", value: "ERROR" },
          ]}
          {...form.getInputProps("status")}
          defaultValue={formatterProps?.status}
          error={form.isDirty("status") ? "저장 필요" : ""}
          styles={{
            input: {
              color: form.isDirty("status") ? "red !important" : "",
              borderColor: form.isDirty("status") ? "red !important" : "",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
        <Update.DateInput
          popoverProps={{ withinPortal: true }}
          icon={<IconCalendar size="1.2rem" />}
          label="예정일"
          valueFormat="YYYY-MM-DD"
          {...form.getInputProps("scheduledAt")}
          error={form.isDirty("scheduledAt") ? "저장 필요" : ""}
          styles={{
            input: {
              color: form.isDirty("scheduledAt") ? "red !important" : "",
              borderColor: form.isDirty("scheduledAt") ? "red !important" : "",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
        <Update.ButtonBox>
          <Update.Button color="gray" onClick={closeModal}>
            취소
          </Update.Button>
          <Update.Button
            disabled={validateFormValues({
              pageType: "transportation",
              values: form.values,
            })}
            onClick={onSubmit}
          >
            배차 수정
          </Update.Button>
        </Update.ButtonBox>
      </Update.Wrapper>
    </>
  );
};
