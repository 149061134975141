import { mutateItems } from "@/api/items/useItemQuery";
import { customFunctions } from "@/config/customFunction";
import { itemTypeColor } from "@/constants/items";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { ItemsUpdateForm } from "@/features/item/components/form/ItemsUpdateForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { useLoader } from "@/hooks/useLoader";
import DefaultInstance from "@/instance/axios";
import { handleErrorResponse } from "@/utils/errorMessage";
import { dataStyle, excelDownLoad, headerStyle } from "@/utils/excelDownLoad";
import { ActionIcon, Avatar, Badge, Button, Flex, Text, Tooltip } from "@mantine/core";
import {
  ItemGradeViewGet200ResponseRowsInner,
  ItemsGet200Response,
  ItemsGet200ResponseRowsInner,
  ItemsGet200ResponseRowsInnerItemTypeEnum,
  MasterApiItemsItemIdDeleteRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import {
  IconEdit,
  IconFileSpreadsheet,
  IconFilterPlus,
  IconRecycle,
  IconSearch,
  IconTrash,
} from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo } from "react";
import { Column, SelectColumn } from "react-data-grid";
import { useTranslation } from "react-i18next";

export const BOMSearchPage = () => {
  const { LoadingOverlay, setLoading } = useLoader();
  const { openModal, closeModal } = useModal();
  const { t } = useTranslation();
  const { query, setQuery, search, searchFields, searchFieldsHeader, refetch, resetQueryStrings } =
    useStandardLayout();

  useEffect(() => {
    if (!query.length) {
      setQuery(() => [
        {
          itemType: {
            $in: [
              ItemsGet200ResponseRowsInnerItemTypeEnum.PRODUCT,
              ItemsGet200ResponseRowsInnerItemTypeEnum.SEMI_PRODUCT,
            ],
          },
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const { mutate: deleteMutate } = useMutation(
    (params: MasterApiItemsItemIdDeleteRequest) =>
      mutateItems.delete(params).mutationFn(params as MasterApiItemsItemIdDeleteRequest | any),
    {
      onSuccess: () => {
        refetch();
        customAlert("품목 삭제에 성공하였습니다.", "삭제 성공", "green");
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({ error, errorContext: "품목 코드" }),
          "품목 삭제 실패",
          "red",
        );
      },
    },
  );

  const columns: readonly Column<ItemsGet200ResponseRowsInner>[] = useMemo(
    () => [
      {
        ...SelectColumn,
        resizable: true,
      },
      {
        key: "itemType",
        name: "품목군",
        sortable: true,
        resizable: true,
        formatter: ({ row }) => {
          return (
            <Badge size="lg" color={itemTypeColor[row.itemType]}>
              {t(row.itemType)}
            </Badge>
          );
        },
      },
      {
        key: "name",
        name: "품목명",
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          const detailItemView = () => {
            openModal(<ItemsDetailForm itemCode={formatterProps?.row.code} />, null, "품목 상세");
          };

          return <LinkAnchor onClick={detailItemView}>{formatterProps.row.name}</LinkAnchor>;
        },
      },
      ...(customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING
        ? [
            {
              key: "gradeName",
              name: "Grade명",
              sortable: true,
              resizable: true,
            },
          ]
        : []),
      {
        key: "code",
        name: "품목코드",
        sortable: true,
        resizable: true,
      },
      {
        key: "options",
        name: "액션",
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          const detailItemView = () => {
            openModal(<ItemsDetailForm itemCode={formatterProps?.row.code} />, null, "품목 상세");
          };

          const updateItemActionView = () => {
            openModal(
              <ItemsUpdateForm formatterProps={formatterProps?.row} />,
              null,
              "품목 수정",
              true,
            );
          };

          const deleteItemAction = () => {
            openModal(
              <ConfirmForm
                message="선택한 행을 삭제하시겠습니까?"
                yesCallback={() => {
                  deleteMutate({ itemId: formatterProps?.row.id });
                }}
                noCallback={() => {}}
                commonCallback={() => closeModal(null)}
              />,
              null,
              "품목 삭제",
            );
          };

          return (
            <Flex w="100%" h="100%" justify="center" align="center" gap="md">
              <ActionIcon variant="subtle" onClick={detailItemView} color="blue">
                <IconSearch />
              </ActionIcon>
              <ActionIcon variant="subtle" onClick={updateItemActionView} color="yellow">
                <IconEdit />
              </ActionIcon>
              <ActionIcon variant="subtle" onClick={() => deleteItemAction()} color="red">
                <IconTrash />
              </ActionIcon>
            </Flex>
          );
        },
      },
    ],
    [closeModal, deleteMutate, openModal, t],
  );

  const excelDownLoadAction = useCallback(async () => {
    try {
      setLoading(true);

      const response = await DefaultInstance.itemGradeViewFindPost({
        query: JSON.stringify({ $and: query }),
        search,
        searchFields: searchFields.length
          ? searchFields
          : searchFieldsHeader
              .filter((item) => item.category === "text" && !item.isEnum)
              .map((item) => item.value),
      });

      const data = response.data;

      const headerRow = [
        { v: "품목군", t: "s", s: headerStyle },
        { v: "품목명", t: "s", s: headerStyle },
        { v: "Grade", t: "s", s: headerStyle },
        { v: "품목코드", t: "s", s: headerStyle },
      ];

      const dataRows =
        data.map((item: ItemGradeViewGet200ResponseRowsInner) => [
          {
            v: item.itemType ? t(item.itemType) : "",
            t: "s",
            s: dataStyle,
          },
          {
            v: item.name ? item.name.toString() : "",
            t: "s",
            s: dataStyle,
          },
          {
            v: item.gradeName ? item.gradeName.toString() : "",
            t: "s",
            s: dataStyle,
          },
          {
            v: item.code ? item.code.toString() : "",
            t: "s",
            s: dataStyle,
          },
        ]) ?? [];

      await excelDownLoad({
        headerRow,
        dataRows,
        colWidths: [120, 120, 120, 120],
        fileName: "품목별_BOM_조회",
      });
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  }, [query, search, searchFields, searchFieldsHeader, setLoading, t]);

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <LoadingOverlay />
      <Flex p="sm" justify="flex-end" rowGap="md" gap="sm">
        <Button
          color="teal"
          rightIcon={<IconFileSpreadsheet />}
          onClick={() => excelDownLoadAction()}
        >
          엑셀 다운로드
        </Button>
        <Button rightIcon={<IconRecycle />} color="teal" onClick={() => resetQueryStrings()}>
          검색값 초기화
        </Button>
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          품목별 BOM 조회
        </Text>
        <Text fz="xs">우리 회사에서 다루는 품목별 BOM에 대해 조회할 수 있는 페이지 입니다.</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip
              label={
                '사용자 정의 필터입니다. "필터 추가" 또는 "정렬 추가" 버튼을 클릭하여 조건을 입력합니다.'
              }
            >
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<ItemsGet200Response, ItemsGet200ResponseRowsInner> columns={columns} />
        </StandardLayoutTableWrapper>
        <PaginationBox<ItemsGet200Response> />
      </Flex>
    </Flex>
  );
};
