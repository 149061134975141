import { useModal } from "@/features/modal/ModalStackManager";
import { ProductionPlanViewForm } from "@/features/productionPlan/view";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { setToLocaleString } from "@/utils/unitMark";
import styled from "@emotion/styled";
import { Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

interface WeekWorkProductionPlanHeadProps {
  data: any;
}
export const WeekWorkProductionPlanRow = (params: WeekWorkProductionPlanHeadProps) => {
  const { data } = params;
  const {
    id,
    itemGradeName,
    itemGradeCode,
    itemCategoryName,
    itemType,
    abstractItemCode,
    itemCode,
    itemName,
    targetQuantity,
    unit,
    createdAt,
  } = data;

  const { openModal } = useModal();
  const { t } = useTranslation();

  return (
    <>
      <Tr item="center">
        <td>
          <DetailLink
            w={"100%"}
            onClick={() => openModal(<ProductionPlanViewForm ProductionPlanId={id} />, null, "")}
          >
            {itemName} {t(itemType)} {itemGradeName} {itemCategoryName} {abstractItemCode}
          </DetailLink>
        </td>
      </Tr>
      <Tr item="right">
        <td>
          <Text fz="md" ta="right">
            중량 : {setToLocaleString(targetQuantity)} {unit}
          </Text>
        </td>
      </Tr>
    </>
  );
};

const Tr = styled.tr<{ item: string }>`
  width: 100%;
  display: flex;
  justify-content: ${(props) => props.item};
  align-items: ${(props) => props.item};
`;
