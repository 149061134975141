import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useWorkState } from "../hooks/useWorkState";

interface WeekHeaderProps {
  children: (weeks: (dayjs.Dayjs | null)[][]) => React.ReactNode;
  start?: Date | null;
  end?: Date | null;
}

export const WeekHeader = (params: WeekHeaderProps) => {
  const { children, start, end } = params;
  const state = useWorkState();

  const startDate = dayjs(start ?? state.date[0]);
  const endDate = dayjs(end ?? state.date[1]);

  const weeks: (dayjs.Dayjs | null)[][] = [];
  let currentDate = startDate;

  const firstWeek: (dayjs.Dayjs | null)[] = Array(7).fill(null);
  for (let i = startDate.day(); i < 7; i++) {
    firstWeek[i] = currentDate;
    currentDate = currentDate.add(1, "day");
  }
  weeks.push(firstWeek);

  while (currentDate.add(6, "day").isBefore(endDate)) {
    const week: (dayjs.Dayjs | null)[] = [];
    for (let i = 0; i < 7; i++) {
      week.push(currentDate);
      currentDate = currentDate.add(1, "day");
    }
    weeks.push(week);
  }

  if (currentDate.isBefore(endDate) || currentDate.isSame(endDate, "day")) {
    const lastWeek: (dayjs.Dayjs | null)[] = Array(7).fill(null);
    for (let i = 0; i <= endDate.day(); i++) {
      lastWeek[i] = currentDate;
      currentDate = currentDate.add(1, "day");
    }
    weeks.push(lastWeek);
  }

  return <>{children(weeks)}</>;
};

export const Th = styled.th<{
  minWidth?: number;
  width?: number;
}>`
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}rem` : `auto`)};
  width: ${(props) => (props.width ? `${props.width}%` : `auto`)};
`;
