import { customFunctions } from "@/config/customFunction";
import { itemTypeColor } from "@/constants/items";
import { AbstractItemDetailForm } from "@/features/abstractItem/form/detailForm";
import { EquipmentDetailForm } from "@/features/equipment/components/form/EquipmentDetailForm";
import { GradeDetailForm } from "@/features/grade/components/form/GradeDetailForm";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { ItemCategoryDetailForm } from "@/features/itemCategory/form/DetailForm";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { OperationDetailForm } from "@/features/operations/components/form/operationDetailForm";
import { ProductionPlanViewForm } from "@/features/productionPlan/view";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import { WorkView } from "@/features/work/WorkView/WorkView";
import { DateHeader } from "@/features/workLog/DateHeader";
import { ActionHeader } from "@/features/workLog/WorkLogActionHeader";
import { setToLocaleString } from "@/utils/unitMark";
import { setWorkStatus, statusObj, trackingStatusColor } from "@/utils/workStatus";
import { Avatar, Badge, Button, Chip, Flex, Group, Text, Tooltip } from "@mantine/core";
import {
  ItemGradeViewGet200ResponseRowsInnerItemTypeEnum,
  WorkLogsGet200Response,
  WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum,
  WorkLogsWorksGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconFilterPlus } from "@tabler/icons-react";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { Column } from "react-data-grid";
import { useTranslation } from "react-i18next";

// 작업 상태 화면에 표시 갯수 threshold
const MAX_DISPLAY = 10;

export const WorkLogPage = () => {
  const { openModal } = useModal();
  const { query, setQuery, startDate, endDate, data } = useStandardLayout();
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [dataLength, setDataLength] = useState(
    (data as AxiosResponse<WorkLogsGet200Response, any>)?.data?.rows?.length,
  );
  const workLogTypeQuery = query.find((q) => q && "workLogType" in q);
  const workLogTypeQueryData = workLogTypeQuery?.workLogType;
  const prevLogTypeLength = useRef(workLogTypeQueryData?.length);
  const prevLogTypeData = useRef<string[]>(workLogTypeQueryData || []);
  // const prevDataLength = useRef<number>(
  //   (data as AxiosResponse<WorkLogsGet200Response, any>)?.data?.total || 0,
  // );

  const [chipState, setChipState] = useState(() => {
    // query 배열에서 workLogType 키를 가진 객체를 찾고, 그 값을 반환하거나 workStatus의 키 목록으로 초기화

    const response: string[] = [];

    if (workLogTypeQueryData && Array.isArray(workLogTypeQueryData)) {
      const data: string[] =
        workLogTypeQueryData.length > 0 ? workLogTypeQueryData : ["INPUT", "PRODUCTION"];
      response.push(...data);
    }

    return response;
  });

  // workLogType 기본값을 유지하기 위한 useEffect
  useEffect(() => {
    if (
      !workLogTypeQueryData ||
      !Array.isArray(workLogTypeQueryData) ||
      workLogTypeQueryData.length === 0
    ) {
      setChipState(["INPUT", "PRODUCTION"]);
    }
  }, [workLogTypeQueryData]);

  const prevDataRef = useRef<number | null>(null);

  useEffect(() => {
    const currentDataTotal = Number(
      (data as AxiosResponse<WorkLogsGet200Response, any>)?.data?.total || 0,
    );

    // 🔥 이전 값을 저장
    const prevValue = prevDataRef.current;

    // 데이터가 0이 되는 경우 무시하도록 수정
    if (currentDataTotal === 0 && prevValue !== null) {
      return;
    }

    // workLogTypeQueryData 변경 여부 확인
    const isWorkLogTypeChanged =
      JSON.stringify(prevLogTypeData.current) !== JSON.stringify(workLogTypeQueryData);

    if (isWorkLogTypeChanged) {
      const addedValues =
        workLogTypeQueryData?.filter((value: any) => !prevLogTypeData.current.includes(value)) ||
        [];

      if (
        addedValues.length > 0 &&
        !(
          addedValues.length === 2 &&
          addedValues.includes("INPUT") &&
          addedValues.includes("PRODUCTION")
        )
      ) {
        // ✅ 데이터 총합이 동일한 경우에만 customAlert 호출
        if (
          prevValue !== null &&
          prevValue === currentDataTotal // ✅ 값이 동일할 경우 customAlert 호출
        ) {
          customAlert(
            `"추가된 값", ${addedValues.join(", ")},"이지만 data가 없습니다."`,
            "info",
            "",
          );
        } else if (
          prevValue !== null &&
          currentDataTotal > prevValue // 🚨 값이 증가한 경우 customAlert 차단
        ) {
          console.log("값이 증가했으므로 customAlert 호출 차단");
        }
      }

      // 🔥 prevLogTypeData를 최신화
      prevLogTypeData.current = [...(workLogTypeQueryData || [])];
    }

    // ✅ prevDataRef 업데이트 시점 개선
    prevDataRef.current = currentDataTotal;
  }, [workLogTypeQueryData, data]);

  useEffect(() => {
    setQuery((prev) => {
      const newQuery = [
        {
          workLogType: chipState,
        },
        {
          createdAt: {
            $gte: dayjs(startDate).startOf("day").toISOString(),
            $lte: dayjs(endDate).endOf("day").toISOString(),
          },
        },
        {
          $or: [{ description: { $eq: null } }, { description: { $ne: "ACTION" } }],
        },
      ];
      return newQuery;
    });
  }, [chipState, startDate, endDate]);

  const columns: readonly Column<WorkLogsWorksGet200ResponseRowsInner>[] = [
    {
      key: "id",
      name: "로그번호",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return <Flex justify="flex-end">{formatterProps.row.id}</Flex>;
      },
    },
    ...(customFunctions.ADD_WORK_LOG_ERP_WORK_ORDER_COLUMN
      ? [
          {
            key: "erpWorkOrderNo",
            name: "ERP 작업번호",
            sortable: true,
            resizable: true,
            width: 140,
            formatter: (formatterProps: any) => {
              return <Flex justify="flex-end">{formatterProps.row.erpWorkOrderNo}</Flex>;
            },
          },
        ]
      : []),
    {
      key: "createdAt",
      name: "일시",
      width: 160,
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <Flex h="100%" align="center">
            {row.createdAt !== null ? dayjs(row.createdAt).format("YYYY-MM-DD HH:mm:ss") : "-"}
          </Flex>
        );
      },
    },
    {
      key: "equipment.name",
      name: "설비코드(설비명)",
      width: 160,
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <LinkAnchor
            onClick={() => {
              openModal(
                <EquipmentDetailForm equipmentCode={row.equipmentCode} />,
                null,
                "설비 상세",
              );
            }}
          >
            {row.equipmentCode}({row?.equipment?.name})
          </LinkAnchor>
        );
      },
    },
    {
      key: "operationCode",
      name: "공정코드",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <LinkAnchor
            onClick={() => {
              openModal(
                <OperationDetailForm operationCode={row.operationCode} />,
                null,
                "공정 상세",
              );
            }}
          >
            {row.operationCode}
          </LinkAnchor>
        );
      },
    },
    {
      key: "workLogType",
      name: "타입",
      width: 110,
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        const badgeColor =
          trackingStatusColor[row.workLogType as WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum] ||
          "defaultColor";
        return (
          <Badge size="lg" color={badgeColor}>
            {setWorkStatus(row.workLogType)}
            {row.workLogType === "TIME_TRACKING_PAUSE" && `(${row.downtimeReasonName})`}
            {row.workLogType === "DEFECT" && `(${row.defectName})`}
          </Badge>
        );
      },
    },
    {
      key: "quantity",
      name: "수량",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <Flex justify="flex-end">
            {row.quantity !== null
              ? row.workLogType === "CONSUME_IDENTIFIED"
                ? setToLocaleString(row.quantity ? -row.quantity : "")
                : setToLocaleString(row.quantity)
              : "0"}{" "}
            {row.unitText}
          </Flex>
        );
      },
    },
    {
      key: "totalQuantity",
      name: "양품수량",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <Flex justify="flex-end">
            {["PRODUCTION", "LOSS", "CANCEL_PRODUCTION", "CANCEL_LOSS"].includes(
              row.workLogType as string,
            )
              ? `${setToLocaleString(row?.currentLotSummary?.totalQuantity)} ${row.unitText}`
              : ""}
          </Flex>
        );
      },
    },
    // {
    //   key: "itemData.name",
    //   name: "품목명",
    //   sortable: true,
    //   resizable: true,
    //   formatter: ({ row }) => {
    //     return (
    //       <Flex align="center">
    //         {row.logData?.incoming
    //           ? row.logData?.incoming.itemData?.name
    //           : row.logData?.outgoing?.itemData?.name}
    //       </Flex>
    //     );
    //   },
    // },
    {
      key: "itemType",
      name: "품목군",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <Badge
            size="lg"
            color={
              itemTypeColor[
                row.itemWithGrade?.itemType as ItemGradeViewGet200ResponseRowsInnerItemTypeEnum
              ]
            }
          >
            {t(row.itemWithGrade?.itemType as ItemGradeViewGet200ResponseRowsInnerItemTypeEnum)}
          </Badge>
        );
      },
    },
    {
      key: "itemName",
      name: "품목명",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(<ItemsDetailForm itemCode={row.itemWithGrade?.code} />, null, "")
            }
          >
            {row.itemWithGrade?.name}
          </LinkAnchor>
        );
      },
    },
    {
      key: "gradeName",
      name: "Grade",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <GradeDetailForm gradeCode={row.itemWithGrade?.gradeCode} />,
                null,
                "Grade 상세",
              )
            }
          >
            {row.itemWithGrade?.gradeName}
          </LinkAnchor>
        );
      },
    },
    {
      key: "itemCategoryName",
      name: "Color",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <ItemCategoryDetailForm ItemCategoryName={row.itemWithGrade?.itemCategoryName} />,
                null,
                "Color 상세",
              )
            }
          >
            {row.itemWithGrade?.itemCategoryName}
          </LinkAnchor>
        );
      },
    },
    {
      key: "abstractItemCode",
      name: "형태",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <AbstractItemDetailForm AbstractItemCode={row.itemWithGrade?.abstractItemCode} />,
                null,
                "형태 상세",
              )
            }
          >
            {row.itemWithGrade?.abstractItemCode}
          </LinkAnchor>
        );
      },
    },
    {
      key: "itemData.spec",
      name: "규격",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <Flex align="center">
            {row.logData?.incoming
              ? row.logData?.incoming.itemData?.spec
              : row.logData?.outgoing?.itemData?.spec}
          </Flex>
        );
      },
    },
    {
      key: "itemData.unitText",
      name: "단위",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return <Flex align="center">{row.itemData?.unitText}</Flex>;
      },
    },
    {
      key: "lotData.name",
      name: "로트명",
      sortable: true,
      resizable: true,
      width: 180,
      formatter: ({ row }) => {
        return (
          <>
            {row.workLogType === "CONSUME_IDENTIFIED" ? (
              <div>
                <span>{row.logData?.outgoing?.lotData?.name} </span>
              </div>
            ) : row.logData?.incoming ? (
              <div>
                <span>{row.logData?.incoming?.lotData?.name} </span>
              </div>
            ) : (
              <div>
                <span>{row.logData?.outgoing?.lotData?.name} </span>
              </div>
            )}
          </>
        );
      },
    },
    {
      key: "lotData.expiration",
      name: "로트 유효기한",
      sortable: true,
      resizable: true,
      width: 160,
      formatter: ({ row }) => {
        return (
          <>
            {row.logData?.incoming ? (
              <div>
                <span>
                  {row.logData?.incoming?.lotData?.expiration
                    ? dayjs(row.logData?.incoming?.lotData.expiration).format("YYYY-MM-DD HH:mm:ss")
                    : ""}
                </span>
              </div>
            ) : (
              <div>
                <span>
                  {row.logData?.outgoing?.lotData?.expiration
                    ? dayjs(row.logData?.outgoing?.lotData.expiration).format("YYYY-MM-DD HH:mm:ss")
                    : ""}
                </span>
              </div>
            )}
          </>
        );
      },
    },
    {
      key: "locationData.name",
      name: "투입 로케이션",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <LinkAnchor
            onClick={() => {
              openModal(
                <LocationDetailForm
                  locationCode={
                    row?.logData?.incoming?.locationCode || row?.logData?.outgoing?.locationCode
                  }
                />,
                null,
                "로케이션 상세",
              );
            }}
          >
            {row.logData?.incoming
              ? row.logData?.incoming.locationData?.name
              : row.logData?.outgoing?.locationData?.name}
          </LinkAnchor>
        );
      },
    },
    {
      key: "workId",
      name: "작업지시번호",
      width: 30,
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <Flex h="100%" justify="flex-end" align="center">
            <LinkAnchor
              onClick={() => {
                openModal(<WorkView workId={row.workId} />, null, "작업지시");
              }}
            >
              {row.workId}
            </LinkAnchor>
          </Flex>
        );
      },
    },
    {
      key: "productionPlanId",
      name: "생산계획번호",
      width: 30,
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <Flex h="100%" justify="flex-end" align="center">
            <LinkAnchor
              onClick={() => {
                openModal(
                  <ProductionPlanViewForm ProductionPlanId={row.productionPlanId} />,
                  null,
                  "생산계획",
                );
              }}
            >
              {row.productionPlanId}
            </LinkAnchor>
          </Flex>
        );
      },
    },
    {
      key: "creatorUserName",
      name: "작업자",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <LinkAnchor
            onClick={() => openModal(<UsersDetailForm UserCode={row?.creatorUserCode} />, null, "")}
          >
            {row?.creatorUserName}
          </LinkAnchor>
        );
      },
    },
  ];

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex direction="column" p="sm" justify="space-between" rowGap="md">
        <ActionHeader />
        <DateHeader />
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          작업일지 리스트
        </Text>
        <Text fz="xs">우리 회사에서 다루는 작업 일지를 조회합니다.</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip
              label={
                '사용자 정의 필터입니다. "필터 추가" 또는 "정렬 추가" 버튼을 클릭하여 조건을 입력합니다.'
              }
            >
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Chip.Group multiple value={chipState}>
        <Group px="sm">
          {Object.keys(statusObj).map((key: string, index: number) => {
            return (
              (isExpanded || index < MAX_DISPLAY) && (
                <Chip
                  variant="filled"
                  size="sm"
                  radius="xs"
                  color={trackingStatusColor[key]}
                  key={key}
                  value={key}
                  checked={chipState && chipState.includes(key)}
                  onClick={(e) => {
                    const targetValue = e.currentTarget.value;
                    if (chipState.includes(targetValue)) {
                      setChipState(chipState.filter((chip: string) => chip !== targetValue));
                    } else {
                      setChipState([...chipState, targetValue]);
                    }
                  }}
                >
                  {statusObj[key]}
                </Chip>
              )
            );
          })}
          {Object.keys(statusObj).length > MAX_DISPLAY && (
            <Button
              onClick={() => setIsExpanded(!isExpanded)}
              radius="xs"
              size="xs"
              fz="sm"
              variant="filled"
              color={isExpanded ? "gray" : "blue"}
            >
              {isExpanded ? "숨기기" : "더보기"}
            </Button>
          )}
        </Group>
      </Chip.Group>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<WorkLogsGet200Response, WorkLogsWorksGet200ResponseRowsInner>
            columns={columns}
          />
        </StandardLayoutTableWrapper>
        <PaginationBox<WorkLogsGet200Response> />
      </Flex>
    </Flex>
  );
};
