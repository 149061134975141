import { BetweenFlex } from "@/features/ui/PMS/\bfactory/betweenFlex";
import { ColumnFlex } from "@/features/ui/PMS/\bfactory/columnFlex";
import { Button, Flex, Text } from "@mantine/core";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";

export const PressMonitoringFactoryPage = () => {
  const [options, setOptions] = useState<any>({
    series: [],
    options: {
      chart: {
        height: 100,
        type: "rangeBar",
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "30%",
        },
      },
      fill: {
        type: "solid",
      },
      grid: {
        borderColor: "rgba(53, 59, 72, .46)",
        columns: {
          colors: ["#f3f4f5", "#fff"],
          opacity: 1,
        },
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 24,
        },
      },
    },
  });

  return (
    <>
      <Flex gap="md" direction={"column"}>
        <Flex justify={"space-between"}>
          <Text size="xl">Extruder별 분석 모니터링</Text>
          <Flex>
            <Button component={Link} to="/pressMonitoring/factoryMonitoring/pageSetting">
              페이지 설정
            </Button>
          </Flex>
        </Flex>
        <Flex>
          <Flex style={{ backgroundColor: "wheat" }} p="md" direction={"column"}>
            <Text size="lg" pb="sm">
              정기계
            </Text>
            <Flex gap="sm" direction={"column"}>
              <Flex gap="sm" direction={"row"}>
                <ColumnFlex title="기계 이름" data="정기계" />
                <ColumnFlex title="제조 번호" data="M-12345" />
                <ColumnFlex title="모델" data="-" />
                <ColumnFlex title="CODE" data="-" />
                <ColumnFlex title="품명/스펙" data="-/-" />
                <ColumnFlex title="설정" />
              </Flex>
              <Flex gap="sm">
                <Flex gap="sm" direction={"column"}>
                  <ColumnFlex title="지시 고유 번호" data="-" height="4rem" />
                  <ColumnFlex title="작업자" data="-" height="4rem" />
                  <BetweenFlex
                    topTitle="현재 생산량"
                    topData="0"
                    bottomTitle="목표 생산량"
                    bottomData="0"
                  />
                  <BetweenFlex
                    topTitle="금일 총 생산량"
                    topData="0"
                    bottomTitle="금일 목표량"
                    bottomData="0"
                  />
                </Flex>
                <Flex w="28.5rem">
                  <ReactApexChart
                    options={options.options}
                    series={options.series}
                    type="rangeBar"
                  />
                </Flex>
                <Flex gap="sm" direction={"column"}>
                  <ColumnFlex title="작업지시 목표 생산량" data="0" height="4rem" />
                  <ColumnFlex title="CH 1 (좌)" data="0" dataUnit="ton" height="4rem" />
                  <ColumnFlex title="CH 2 (우)" data="0" dataUnit="ton" height="4rem" />
                  <ColumnFlex title="일량" data="0" dataUnit="kgf/cm2" height="4rem" />
                </Flex>
                <Flex>
                  <Flex
                    direction={"column"}
                    justify={"center"}
                    align={"center"}
                    style={{ backgroundColor: "#f5f5f5", borderRadius: "10px" }}
                    w="9rem"
                    h="18rem"
                  >
                    <Text size="sm">작업지시 목표 생산량</Text>
                    <Text size="sm">0</Text>
                  </Flex>
                </Flex>
              </Flex>
              <Flex gap="sm" direction={"row"}>
                <ColumnFlex title="프레스 상태" data="전원 OFF" height="4rem" />
                <ColumnFlex title="키캠 상태" data="OFF" height="4rem" />
                <BetweenFlex
                  topTitle="현재 생산량"
                  topData="0"
                  bottomTitle="목표 생산량"
                  bottomData="0"
                />
                <ColumnFlex title="SPM" data="0" height="4rem" />
                <ColumnFlex title="슬라이드 모터 잔류" data="0" dataUnit="A" height="4rem" />
                <ColumnFlex title="메인 모터 전류" data="0" dataUnit="A" height="4rem" />
              </Flex>
              <Flex gap="sm" direction={"row"}>
                <BetweenFlex
                  topTitle="기계 가동시간"
                  topData="-"
                  bottomTitle="기계 비가동시간"
                  bottomData="-"
                />
                <ColumnFlex title="기계 가동률" data="0" dataUnit="%" height="4rem" />
                <ColumnFlex title="UPH" data="0" dataUnit="ea" height="4rem" />
                <ColumnFlex title="남은시간" data="-" height="4rem" />
                <ColumnFlex title="부하율" data="0" dataUnit="%" height="4rem" />
                <ColumnFlex title="에러 코드" data="-" height="4rem" />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
