import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { setToLocaleString } from "@/utils/unitMark";
import { Table } from "@mantine/core";
import dayjs from "dayjs";

export const LotList = ({ lotsData, unitText }: any) => {
  const transformedData =
    lotsData?.length &&
    lotsData?.flatMap((item: any) =>
      item.inventories.map((inventory: any) => ({
        itemCode: item.itemCode,
        name: item.name,
        expiration: item.expiration,
        location: inventory?.locationCode || "-",
        quantity: inventory?.quantity || "-",
      })),
    );

  const { openModal } = useModal();

  return (
    <Table style={{ borderCollapse: "separate", borderSpacing: 0 }}>
      <thead style={{ position: "sticky", top: 0, background: "white", zIndex: 10 }}>
        <tr>
          <th rowSpan={2}>품목코드</th>
          <th rowSpan={2}>로트명</th>
          <th rowSpan={2}>로트 유효기한</th>
          <th colSpan={2}>재고</th>
        </tr>
        <tr>
          <th>위치</th>
          <th>수량</th>
        </tr>
      </thead>
      <tbody>
        {lotsData?.length ? (
          <>
            {transformedData?.map((item: any) => (
              <tr key={item.itemCode + item.name + item.location}>
                <td>{item.itemCode}</td>
                <td>{item.name}</td>
                <td>{item.expiration ? dayjs(item.expiration).format("YYYY-MM-DD") : ""}</td>
                <td>
                  <DetailLink
                    onClick={() =>
                      openModal(<LocationDetailForm locationCode={item.location} />, null, "")
                    }
                    justify={"flex-start"}
                  >
                    {item.location}
                  </DetailLink>
                </td>
                <td>
                  {setToLocaleString(item.quantity)} {unitText}
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={5}>로트 정보가 없습니다.</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};
