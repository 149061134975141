import { LeftActionBar, RightActionBar } from "@/features/layout";
import { useModal } from "@/features/modal/ModalStackManager";
import { ProductionPlanCreateForm } from "@/features/productionPlan/form/CreateForm";
import { QueryType } from "@/features/standardLayout/Context";
import { useQueryString } from "@/features/standardLayout/useQueryString";
import { Calendar, isCalendarDate } from "@/features/ui/Calendar";
import { DefectTypeItem } from "@/features/ui/DefectTypeSet";
import { WorkActionBar } from "@/features/workByManagement/WorkActionBar";
import { WorkHeader } from "@/features/workByManagement/WorkHeader";
import { WorkProductionPlanTable } from "@/features/workByManagement/WorkProductionPlanTable";
import { WorkProductionPlanTableHead } from "@/features/workByManagement/WorkProductionPlanTableHead";
import { WorkProductionPlanTableRow } from "@/features/workByManagement/WorkProductionPlanTableRow";
import { WorkSiteSelect } from "@/features/workByManagement/WorkSiteSelect";
import { WorkTable } from "@/features/workByManagement/WorkTable";
import { WorkTableActionBar } from "@/features/workByManagement/WorkTableActionBar";
import { WorkTableBody } from "@/features/workByManagement/WorkTableBody";
import { WorkTableHead } from "@/features/workByManagement/WorkTableHead";
import { WorkTableRow } from "@/features/workByManagement/WorkTableRow";
import { WorkTableWrapper } from "@/features/workByManagement/WorkTableWrapper";
import { workPage } from "@/features/workByManagement/api/useWorkPageQuery";
import { useWorkActions } from "@/features/workByManagement/hooks/useWorkActions";
import { useWorkState } from "@/features/workByManagement/hooks/useWorkState";
import { WeekHeader } from "@/features/workByManagement/weekProductionPlanTable/WeekHeader";
import { WeekWorkProductionPlanRow } from "@/features/workByManagement/weekProductionPlanTable/WeekWokrProductionPlanRow";
import useLocalStorage from "@/hooks/useLocalStorage";
import { getStartAndEndOfDateRange } from "@/utils/dateTimeUtil";
import styled from "@emotion/styled";
import { Button, Flex, Pagination, Select, Text } from "@mantine/core";
import {
  ProductionPlansGet200Response,
  ProductionPlansGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import { useQueries } from "@tanstack/react-query";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useMemo, useState } from "react";

dayjs.extend(utc);

const Work = {
  ActionBar: WorkActionBar,
  LeftActionBar: LeftActionBar,
  RightActionBar: RightActionBar,
  SiteSelect: WorkSiteSelect,
  Select: Select,
  Button: Button,
  WorkHeader: WorkHeader,
  WorkTableActionBar: WorkTableActionBar,
  WorkTableWrapper: WorkTableWrapper,
  WorkProductionPlanTable: WorkProductionPlanTable,
  WorkProductionPlanTableHead: WorkProductionPlanTableHead,
  WorkProductionPlanTableRow: WorkProductionPlanTableRow,
  WorkTable: WorkTable,
  WorkTableHead: WorkTableHead,
  WorkTableBody: WorkTableBody,
  WorkTableRow: WorkTableRow,
};

export const WorkByProductionPlanWithWeek = () => {
  const state = useWorkState();
  const actions = useWorkActions();
  const [page, setPage] = useQueryString<number>("page", 1);
  const [pageSize, setPageSize] = useQueryString<number>("pageSize", 20);
  const [startDate, setStartDate] = useQueryString<Date | null>("startDate", state.date[0]);
  const [endDate, setEndDate] = useQueryString<Date | null>("endDate", state.date[1]);
  const [params, setParams] = useState<QueryType>({});
  const { openModal } = useModal();
  const [defectTypes, setDefectTypes] = useLocalStorage<DefectTypeItem[][]>("defectTypes", [
    [],
    [],
  ]);

  // URL 파라미터 업데이트
  useEffect(() => {
    const updatedParams = {
      page: page,
      startDate: startDate instanceof Date ? startDate.toISOString() : undefined,
      endDate: endDate instanceof Date ? endDate.toISOString() : undefined,
      pageSize: pageSize,
    };
    setParams((prev) => ({
      ...prev,
      ...updatedParams,
    }));
  }, [page, startDate, endDate]);

  useEffect(() => {
    actions.setResetState();
  }, []);

  const dateRange = useMemo(() => {
    let dates = [];
    let current = dayjs(startDate);
    const end = dayjs(endDate);
    while (current.isBefore(end) || current.isSame(end, "day")) {
      dates.push(current.format("YYYY-MM-DD"));
      current = current.add(1, "day");
    }
    return dates;
  }, [startDate, endDate]);

  const [daysPage, setDaysPage] = useState<number[]>(new Array(dateRange.length).fill(1));

  const queries = useQueries({
    queries: dateRange.map((date, index) => ({
      ...workPage.productionPlan({
        defectCodes: defectTypes && defectTypes[1],
        query: {
          $and: [
            {
              scheduledAt: {
                $between: getStartAndEndOfDateRange(new Date(date), new Date(date)),
              },
            },
          ],
        },
        page: daysPage[index], // 현재 날짜에 맞는 페이지 값 사용
        pageSize: pageSize,
        sort: "-id",
      }),
    })),
  });

  const plansByDate = queries.map((query) => query.data || []);

  const handleCreatePlan = () => {
    openModal(<ProductionPlanCreateForm />, {}, "생산계획 생성", true).then(() => {
      queries.forEach((query) => query.refetch());
    });
  };

  // 요일을 한글로 변환하는 함수
  const getKoreanDay = (date: dayjs.Dayjs | null) => {
    if (!date) return "";
    const days = ["일", "월", "화", "수", "목", "금", "토"];
    return days[date.day()];
  };

  return (
    <WorkExampleContainer>
      <Work.ActionBar>
        <Work.LeftActionBar>
          {/* TODO: 현재 선택된 사업장 => 추가 필요 */}
          {/* <Work.Select data={[]} placeholder="사업장 선택" /> */}
          {/* <Work.SiteSelect /> */}
          {/* TODO: 현재 선택된 라인 => 추후 테이블 추가 필요하고 equipment에 lineId 추가 필요  */}
          {/* <Work.Select data={[]} placeholder="라인 선택" /> */}
          <Work.Button
            leftIcon={<IconCalendar />}
            onClick={() => {
              openModal(
                <Calendar dates={[startDate, endDate]} type="range" />,
                null,
                "날짜 선택",
                true,
              ).then((value) => {
                if (isCalendarDate(value)) {
                  actions.setDate(value);
                  setStartDate(value[0]);
                  setEndDate(value[1]);
                }
              });
            }}
          >
            날짜선택
          </Work.Button>
        </Work.LeftActionBar>
      </Work.ActionBar>
      <Work.WorkHeader dateType="range" startDate={startDate} endDate={endDate} />
      <Work.WorkTableActionBar>
        <Work.LeftActionBar>
          <Work.Button onClick={handleCreatePlan}>생산계획 생성</Work.Button>
        </Work.LeftActionBar>
      </Work.WorkTableActionBar>
      <Work.WorkTableWrapper>
        <Work.WorkProductionPlanTable>
          <WeekHeader start={startDate} end={endDate}>
            {(weeks) => (
              <>
                {weeks.map((week, weekIndex) => (
                  <React.Fragment key={weekIndex}>
                    <tr>
                      {week.map((date, dayIndex) => (
                        <TitleTh key={dayIndex}>
                          {date ? (
                            <Text>
                              {date.format("YYYY-MM-DD")}({getKoreanDay(date)})
                            </Text>
                          ) : (
                            <Text>-</Text>
                          )}
                        </TitleTh>
                      ))}
                    </tr>

                    <tr>
                      {week.map((date, dayIndex) => {
                        if (!date) {
                          return <td key={dayIndex}></td>; // 빈칸 처리
                        }

                        const formattedDate = date.format("YYYY-MM-DD");

                        const targetEntries = (plansByDate ?? []).filter(
                          (entry: ProductionPlansGet200Response | never[]) =>
                            ((entry as ProductionPlansGet200Response)?.rows ?? []).some(
                              (plan: ProductionPlansGet200ResponseRowsInner) =>
                                plan?.scheduledAt &&
                                dayjs.utc(plan.scheduledAt).local().format("YYYY-MM-DD") ===
                                  formattedDate,
                            ),
                        );
                        const dayPlans = targetEntries.flatMap(
                          (entry: ProductionPlansGet200Response | never[]) =>
                            ((entry as ProductionPlansGet200Response)?.rows ?? []).filter(
                              (plan: ProductionPlansGet200ResponseRowsInner) =>
                                plan?.scheduledAt &&
                                dayjs.utc(plan.scheduledAt).local().format("YYYY-MM-DD") ===
                                  formattedDate,
                            ),
                        );

                        const currentPage =
                          (targetEntries[0] as ProductionPlansGet200Response)?.page ?? 1;
                        const totalPages =
                          (targetEntries[0] as ProductionPlansGet200Response)?.totalPages ?? 1;

                        return (
                          <Td key={dayIndex}>
                            {dayPlans.length > 0 ? (
                              <>
                                {dayPlans.map((plan) => (
                                  <WeekWorkProductionPlanRow
                                    key={plan.id}
                                    data={{
                                      id: plan.id,
                                      itemGradeName: plan.itemWithGrade?.gradeName,
                                      itemGradeCode: plan.itemWithGrade?.gradeCode,
                                      itemType: plan.itemWithGrade?.itemType,
                                      itemCategoryName: plan.itemWithGrade?.itemCategoryName,
                                      abstractItemCode: plan.itemWithGrade?.abstractItemCode,
                                      itemCode: plan.itemCode,
                                      itemName: plan.itemName,
                                      targetQuantity: plan.targetQuantity,
                                      unit: plan.unitText,
                                      createdAt: plan.createdAt,
                                    }}
                                  />
                                ))}

                                {totalPages > 1 && (
                                  <Pagination
                                    onChange={(newPage) => {
                                      setDaysPage((prev) => ({
                                        ...prev,
                                        [formattedDate]: newPage,
                                      }));
                                    }}
                                    value={currentPage}
                                    total={totalPages}
                                    size="lg"
                                    radius="sm"
                                  />
                                )}
                              </>
                            ) : (
                              <Flex justify={"center"}>
                                <span>-</span>
                              </Flex>
                            )}
                          </Td>
                        );
                      })}
                    </tr>
                  </React.Fragment>
                ))}
              </>
            )}
          </WeekHeader>
        </Work.WorkProductionPlanTable>
      </Work.WorkTableWrapper>
    </WorkExampleContainer>
  );
};

const WorkExampleContainer = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: center; // flex-start;
  gap: 8px;
`;

const Td = styled.td`
  border: 1px solid #e0e0e0;
`;

const TitleTh = styled.th`
  border: 1px solid #e0e0e0;
  font-size: 14px;
`;
