import { Avatar, Badge, Flex, Text, Tooltip } from "@mantine/core";

import { useModal } from "@/features/modal/ModalStackManager";

import { IconFilterPlus } from "@tabler/icons-react";

import { customFunctions } from "@/config/customFunction";
import { itemTypeColor } from "@/constants/items";
import { DateHeader } from "@/features/inventory/components/DateHeader";
import { LedgerDetailForm } from "@/features/inventory/components/Form/detail/DetailForm";
import { ActionHeader } from "@/features/inventory/components/InventoryActionHeader";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import timeUtil from "@/utils/timeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import {
  InventoriesBetweenGet200Response,
  InventoriesBetweenGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { useEffect, useMemo } from "react";
import { Column, SelectColumn } from "react-data-grid";
import { useTranslation } from "react-i18next";

export const InventoryPage = () => {
  const { openModal } = useModal();
  const { t } = useTranslation();
  const { query, setQuery, sort, setSort, endDate, setParams, startDate } = useStandardLayout();

  useEffect(() => {
    const updatedParams = {
      targetDateString: dayjs(startDate).startOf("day").toISOString(),
      targetDateEndString: dayjs(endDate).endOf("day").toISOString(),
    };

    setParams((prev) => ({
      ...prev,
      ...updatedParams,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    if (!query.length) {
      setQuery(() => [
        {
          quantity: {
            $ne: "0",
          },
        },
      ]);
    }

    // 경남케미칼 커스텀
    if (customFunctions.ADD_INVENTORY_QUANTITY_SORT_FIX && !sort.length) {
      setSort(() => ["-quantity"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, sort]);

  const columns: readonly Column<InventoriesBetweenGet200ResponseRowsInner>[] = useMemo(
    () => [
      {
        ...SelectColumn,
        resizable: true,
      },
      {
        key: "itemType",
        name: "품목군",
        sortable: true,
        resizable: true,
        formatter: ({ row }: { row: InventoriesBetweenGet200ResponseRowsInner }) => {
          return (
            <Badge size="lg" color={itemTypeColor[row?.itemType as keyof typeof itemTypeColor]}>
              {t(row?.itemType as string)}
            </Badge>
          );
        },
      },
      {
        key: "itemName",
        name: "품목명",
        sortable: true,
        resizable: true,
      },
      ...(customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING
        ? [
            {
              key: "gradeName",
              name: "Grade명",
              sortable: true,
              resizable: true,
            },
          ]
        : []),
      {
        key: "itemCategoryName",
        name: "Color",
        sortable: true,
        resizable: true,
      },
      {
        key: "abstractItemCode",
        name: "형태",
        sortable: true,
        resizable: true,
      },
      {
        key: "lot.name",
        name: "로트명",
        sortable: true,
        resizable: true,
        formatter: ({ row }: { row: InventoriesBetweenGet200ResponseRowsInner }) => (
          <Flex align="center">{row.lot?.name}</Flex>
        ),
      },
      {
        key: "locationName",
        name: "위치",
        sortable: true,
        resizable: true,
        formatter: ({ row }: { row: InventoriesBetweenGet200ResponseRowsInner }) => {
          const detailItemAction = () => {
            openModal(
              <LocationDetailForm locationCode={row.locationCode ?? ""} />,
              null,
              "로케이션 상세",
            );
          };
          return <LinkAnchor onClick={detailItemAction}>{row.locationName}</LinkAnchor>;
        },
      },
      {
        key: "createdAt",
        name: "입고일",
        sortable: true,
        resizable: true,
        formatter: ({ row }: { row: InventoriesBetweenGet200ResponseRowsInner }) => {
          return <Flex align="center">{timeUtil(row?.createdAt as string)}</Flex>;
        },
      },
      {
        key: "increaseQuantity",
        name: "추가된 재고",
        sortable: true,
        resizable: true,
        formatter: ({ row }: { row: InventoriesBetweenGet200ResponseRowsInner }) => {
          const betweenIncreaseQuantity = row?.betweenIncreaseQuantity ?? 0;
          return (
            <Flex justify="flex-end">
              {setToLocaleString(betweenIncreaseQuantity)} {row.unitText}
            </Flex>
          );
        },
      },
      {
        key: "decreaseQuantity",
        name: "감소된 재고",
        sortable: true,
        resizable: true,
        formatter: ({ row }: { row: InventoriesBetweenGet200ResponseRowsInner }) => {
          const betweenDecreaseQuantity = row?.betweenDecreaseQuantity ?? 0;
          return (
            <Flex justify="flex-end">
              {setToLocaleString(betweenDecreaseQuantity)} {row.unitText}
            </Flex>
          );
        },
      },
      {
        key: "deltaQuantity",
        name: "재고 변화량",
        sortable: true,
        resizable: true,
        formatter: ({ row }: { row: InventoriesBetweenGet200ResponseRowsInner }) => {
          const betweenDeltaQuantity = row?.betweenDeltaQuantity ?? 0;
          return (
            <Flex justify="flex-end">
              {setToLocaleString(betweenDeltaQuantity)} {row.unitText}
            </Flex>
          );
        },
      },
      {
        key: "quantityAtStart",
        name: "이월 재고",
        sortable: true,
        resizable: true,
        formatter: ({ row }: { row: InventoriesBetweenGet200ResponseRowsInner }) => {
          const quantityAtStart = row?.quantityAtStart ?? 0;
          return (
            <Flex justify="flex-end">
              {setToLocaleString(quantityAtStart)} {row.unitText}
            </Flex>
          );
        },
      },
      {
        key: "quantity",
        name: "최종 재고",
        sortable: true,
        resizable: true,

        formatter: ({ row }: { row: InventoriesBetweenGet200ResponseRowsInner }) => {
          const quantityAtEnd = row?.quantityAtEnd ?? 0;
          const detailItemAction = () => {
            openModal(<LedgerDetailForm data={row} />, null, "재고 상세");
          };
          return (
            <Flex h="100%" justify="flex-end" align="center">
              <LinkAnchor onClick={detailItemAction}>
                {setToLocaleString(quantityAtEnd)} {row.unitText}
              </LinkAnchor>
            </Flex>
          );
        },
      },
    ],
    [openModal, t],
  );

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <ActionHeader />
      <DateHeader />
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          재고 리스트
        </Text>
        <Text fz="xs">우리 회사에서 다루는 재고를 관리합니다.</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip
              label={
                '사용자 정의 필터입니다. "필터 추가" 또는 "정렬 추가" 버튼을 클릭하여 조건을 입력합니다.'
              }
            >
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<InventoriesBetweenGet200Response, InventoriesBetweenGet200ResponseRowsInner>
            columns={columns}
            rowKeyGetter={(row: InventoriesBetweenGet200ResponseRowsInner) => JSON.stringify(row)}
          />
        </StandardLayoutTableWrapper>
        <PaginationBox<InventoriesBetweenGet200Response> />
      </Flex>
    </Flex>
  );
};
