import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";

import { Button, Group, Radio, TextInput } from "@mantine/core";

import { mutateLocations } from "@/api/locations/useLocationsQuery";
import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { SitesAutoComplete } from "@/features/ui/autoComplete/site/sites-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { useForm } from "@mantine/form";
import { MasterApiLocationsLocationIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

const Update = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
});

export const LocationUpdateForm = (params: { formatterProps: any }) => {
  const { closeModal } = useModal();
  const { formatterProps } = params;
  const queryClient = useQueryClient();
  const { mutate: updateMutate } = useMutation(
    (params: MasterApiLocationsLocationIdPutRequest) =>
      mutateLocations
        .update(params)
        .mutationFn(params as MasterApiLocationsLocationIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["locations"]);
        closeModal(formatterProps);
        customAlert("로케이션 수정에 성공하였습니다.", "수정 성공", "green");
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: "로케이션코드" }),
          "로케이션 수정 실패",
          "red",
        );
      },
    },
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>("");
  const [isAvailable, setIsAvailable] = useState(formatterProps.isAvailable ? "true" : "false");

  const form = useForm({
    initialValues: {
      code: formatterProps.code,
      name: formatterProps.name,
      siteCode: formatterProps.siteCode,
      isAvailable: formatterProps.isAvailable ? "true" : "false",
    },
  });

  const onSubmit = () => {
    try {
      updateMutate({
        locationId: formatterProps.id as number,
        locationsGetRequest: {
          code: form.values.code!,
          name: form.values.name!,
          siteCode: form.values.siteCode!,
          isAvailable: isAvailable === "true" ? true : false,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: "로케이션코드" }),
        "로케이션 수정 실패",
        "red",
      );
    }
  };

  return (
    <>
      <Update.Wrapper>
        <Update.Input
          label="로케이션코드"
          withAsterisk
          defaultValue={formatterProps?.code}
          {...form.getInputProps("code")}
          error={form.isDirty("code") ? "저장 필요" : errorMessage ? errorMessage : ""}
          styles={{
            input: {
              color: form.isDirty("code") ? "red !important" : "",
              borderColor: form.isDirty("code") ? "red !important" : "",
            },
            error: { fontSize: 11, color: "red" },
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const regex = /^[A-Za-z0-9_]*$/;
            if (regex.test(e.target.value)) {
              form.getInputProps("code").onChange(e);
              setErrorMessage(""); // 유효한 입력인 경우 에러 메시지 제거
            } else {
              setErrorMessage("한글은 입력할 수 없습니다."); // 유효하지 않은 입력인 경우 에러 메시지 설정
            }
          }}
        />
        <Update.Input
          label="로케이션이름"
          withAsterisk
          defaultValue={formatterProps?.name}
          {...form.getInputProps("name")}
          error={form.isDirty("name") ? "저장 필요" : ""}
          styles={{
            input: {
              color: form.isDirty("name") ? "red !important" : "",
              borderColor: form.isDirty("name") ? "red !important" : "",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
        <SitesAutoComplete
          label="사업장 코드"
          withAsterisk
          width="100%"
          defaultValue={formatterProps?.siteCode}
          {...form.getInputProps("siteCode")}
          error={form.isDirty("siteCode") ? "저장 필요" : ""}
        />
        {customFunctions.ADD_DEFECT_LOCATION && (
          <Radio.Group
            {...form.getInputProps("isAvailable")}
            value={isAvailable}
            onChange={(value: string) => {
              setIsAvailable(value);
              form.setFieldValue("isAvailable", value);
            }}
            name="가용 창고 여부"
            label="가용 창고 여부"
            withAsterisk
          >
            <Group mt="xs">
              <Radio value="true" label="가용" />
              <Radio value="false" label="비가용" />
            </Group>
          </Radio.Group>
        )}
        <Update.ButtonBox>
          <Update.Button color="gray" onClick={closeModal}>
            취소
          </Update.Button>
          <Update.Button
            disabled={validateFormValues({
              pageType: "location",
              values: form.values,
            })}
            onClick={onSubmit}
          >
            로케이션 수정
          </Update.Button>
        </Update.ButtonBox>
      </Update.Wrapper>
    </>
  );
};
