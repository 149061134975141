import { items } from "@/api/items/useItemQuery";
import { useQuery } from "@tanstack/react-query";
import { Text } from "@mantine/core";

// 생산계획 생성 시 화면에서 품목명을 확인하기 위한 컴포넌트, 품목코드로 품목명을 조회하여 표시한다.
interface ItemNameViewProps {
  itemCode?: string;
}

export const ItemNameView = ({ itemCode }: ItemNameViewProps) => {
  const { data: itemTypeData } = useQuery(
    items.codeSearch({
      query: {
        code: itemCode,
      },
    }),
  );

  const itemName = itemTypeData?.data?.rows?.[0]?.name;

  return <Text>{itemName}</Text>;
};
