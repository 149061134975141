import { RoutingBomRow } from "@/custom/features/item/components/form/itemStepperForm/RoutingBomRow";
import { useLoader } from "@/hooks/useLoader";
import { dataStyle, excelDownLoad, headerStyle } from "@/utils/excelDownLoad";
import styled from "@emotion/styled";
import { Button, Flex, Table, Text, Title } from "@mantine/core";
import {
  ItemGradeViewGet200ResponseRowsInner,
  ItemsGet200ResponseRowsInnerRoutingsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconFileSpreadsheet } from "@tabler/icons-react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

interface RoutingBomListProps {
  formData: ItemsGet200ResponseRowsInnerRoutingsInner[] | undefined;
  itemData?: ItemGradeViewGet200ResponseRowsInner | undefined;
}

export const RoutingBomList = ({ formData, itemData }: RoutingBomListProps) => {
  const [bomNumerator, setBomNumerator] = useState<number>(0);
  const { setLoading, LoadingOverlay } = useLoader();
  const { t } = useTranslation();

  const excelDownLoadAction = useCallback(async () => {
    try {
      setLoading(true);

      const headerRow = [
        { v: "품목군", t: "s", s: headerStyle },
        { v: "품목명", t: "s", s: headerStyle },
        { v: "Grade", t: "s", s: headerStyle },
        { v: "Color", t: "s", s: headerStyle },
        { v: "형태", t: "s", s: headerStyle },
        { v: "비율", t: "s", s: headerStyle },
      ];

      // 맨 첫 행은 상단의 내용
      const firstRow = [
        { v: itemData?.itemType ? t(itemData.itemType) : "", t: "s", s: dataStyle },
        { v: itemData?.name ? itemData?.name : "", t: "s", s: dataStyle },
        { v: itemData?.gradeName ? itemData?.gradeName : "", t: "s", s: dataStyle },
        { v: itemData?.itemCategoryName ? itemData?.itemCategoryName : "", t: "s", s: dataStyle },
        { v: itemData?.abstractItemCode ? itemData?.abstractItemCode : "", t: "s", s: dataStyle },
        { v: "", t: "n", s: dataStyle },
      ];

      // 두번째 행은 공백의 행
      const emptyRow = [
        { v: "", t: "s", s: null },
        { v: "", t: "s", s: null },
        { v: "", t: "s", s: null },
        { v: "", t: "s", s: null },
        { v: "", t: "s", s: null },
        { v: "", t: "s", s: null },
      ];

      // 세번째 행부터는 bom의 행 내용들
      const dataRows = (formData ?? []).flatMap((data) =>
        (data.routingBoms ?? []).map((bom) => [
          {
            v: bom.itemWithGrade?.itemType ? t(bom.itemWithGrade.itemType) : "",
            t: "s",
            s: dataStyle,
          },
          { v: bom.itemWithGrade?.name ?? "", t: "s", s: dataStyle },
          {
            v: bom.itemWithGrade?.gradeName ?? "",
            t: "s",
            s: dataStyle,
          },
          {
            v: bom.itemWithGrade?.itemCategoryName ?? "",
            t: "s",
            s: dataStyle,
          },
          {
            v: bom.itemWithGrade?.abstractItemCode ?? "",
            t: "s",
            s: dataStyle,
          },
          { v: bom.quantityNumerator ?? "", t: "n", s: dataStyle },
        ]),
      );

      await excelDownLoad({
        headerRow,
        dataRows: [firstRow, emptyRow, ...dataRows],
        colWidths: [120, 120, 120, 120, 120, 120],
        fileName: "품목_BOM_정보",
      });
    } catch (error) {
      throw error;
    } finally {
      // 로딩 종료
      setLoading(false);
    }
  }, [
    formData,
    itemData?.abstractItemCode,
    itemData?.gradeName,
    itemData?.itemCategoryName,
    itemData?.itemType,
    itemData?.name,
    setLoading,
    t,
  ]);

  return (
    <Flex w="100%" p="md" direction="column" gap="xs">
      <LoadingOverlay />
      <Flex direction={"row"} justify={"space-between"}>
        <Flex direction={"column"}>
          <Title order={2}>공정도</Title>
          <Text fz="xs">품목을 생산하기 위한 공정도와 공정도에 필요한 BOM들을 보여줍니다.</Text>
        </Flex>
        <Flex gap="sm" align="center">
          {itemData && (
            <Button
              color="teal"
              rightIcon={<IconFileSpreadsheet />}
              onClick={() => excelDownLoadAction()}
            >
              엑셀 다운로드
            </Button>
          )}
          <Text fz="lg" mr="lg">
            총 비율 : {bomNumerator} %
          </Text>
        </Flex>
      </Flex>
      <Table withBorder withColumnBorders>
        <thead>
          <tr>
            <Th width={5}>&nbsp;</Th>
            <Th width={5}>시퀀스</Th>
            <Th width={20}>공정명</Th>
            <Th width={10}>소모 시점</Th>
            <Th width={10}>BOM 수정</Th>
          </tr>
        </thead>
        <tbody>
          {formData &&
            formData.map((data, i) => {
              return (
                <RoutingBomRow
                  bomRow={data}
                  key={data.code ?? "" + i}
                  rowIndex={i}
                  bomNumerator={bomNumerator}
                  setBomNumerator={setBomNumerator}
                />
              );
            })}
        </tbody>
      </Table>
    </Flex>
  );
};

const Th = styled.th<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
`;
