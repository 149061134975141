import { inventories } from "@/api/inventories/useInventoriesQuery";
import {
  NoDataTr,
  PaginationWrapper,
  TabBodyTable,
  TabBodyTitle,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  WorkTabBodyContainer,
} from "@/features/work/WorkView/WorkProductionHistory";
import { setDateFormatStringWithTime } from "@/utils/dateTimeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import { Checkbox, Pagination, Text } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
interface WorkProductionInventoryProps {
  lstItemCode: (string | undefined)[];
  locationCode?: string;
}

export const WorkProductionInventory = ({
  lstItemCode,
  locationCode,
}: WorkProductionInventoryProps) => {
  const [activePage, setActivePage] = useState<number>(1);

  // TODO: 데이터 바뀌면 재적용 필요
  // const { data: productionInventory } = useInventoriesGetQuery({
  //     query: {
  //         $and: [
  //             { itemCode: lstItemCode },
  //             { locationCode: locationCode },
  //             { quantity: { $ne: 0 }},
  //         ],
  //     },
  //     activePage: activePage,
  // });
  const { data: productionInventory } = useQuery(
    inventories.get({
      query: {
        $and: [{ itemCode: lstItemCode }, { locationCode: locationCode }, { quantity: { $ne: 0 } }],
      },
      page: activePage,
    }),
  );

  const rows: any[] = productionInventory?.data?.rows ?? [];

  return (
    <WorkTabBodyContainer>
      <TabBodyTitle>
        <Text fz="xl" fw={500}>
          생산 가능 재고
        </Text>
        <Text fz="xs" fw={400}>
          투입 가능한 생산 가능 재고 정보를 표시합니다.
        </Text>
      </TabBodyTitle>
      <TabBodyTable>
        <Thead>
          <Tr>
            <Th width={100}>시퀀스</Th>
            <Th width={180}>원부자재</Th>
            <Th width={200}>원부자재 로트 설정</Th>
            <Th width={200}>투입 로트 정보</Th>
            <Th width={200}>투입일시</Th>
            <Th>수량</Th>
          </Tr>
        </Thead>
        <Tbody>
          {rows.length ? (
            rows.map((inputLot) => (
              <Tr>
                <Td width={100} style={{ justifyContent: "flex-end" }}>
                  {inputLot.id}
                </Td>
                {/* TODO: mantine UI -> NavLink 적용 필요 */}
                <Td width={180}>{inputLot.lotName}</Td>
                <Td width={200}>
                  <Checkbox
                    size="xs"
                    mr={12}
                    label="이름"
                    defaultChecked={inputLot.itemUsesLotName}
                    disabled
                  />
                  <Checkbox
                    size="xs"
                    label="유효기한"
                    defaultChecked={inputLot.itemUsesLotExpiration}
                    disabled
                  />
                </Td>
                <Td width={200}>[LOT] id: {inputLot.lotId}</Td>
                <Td width={200}>{setDateFormatStringWithTime(inputLot.lotCreatedAt)}</Td>
                <Td style={{ justifyContent: "flex-end" }}>
                  {setToLocaleString(inputLot.quantity)} {inputLot?.unitText}
                </Td>
              </Tr>
            ))
          ) : (
            <NoDataTr>
              <Text>생산 가능 재고가 존재하지 않습니다.</Text>
            </NoDataTr>
          )}
        </Tbody>
      </TabBodyTable>
      <PaginationWrapper>
        <Pagination
          onChange={setActivePage}
          value={activePage}
          total={productionInventory?.data?.totalPages ?? 0}
          size="lg"
          radius="sm"
        />
      </PaginationWrapper>
    </WorkTabBodyContainer>
  );
};
