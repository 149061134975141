import { ActionIcon, Badge, Flex, Tooltip } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  // createRow,
  type MRT_ColumnDef,
} from "mantine-react-table";
import { useMemo, useState } from "react";

import { specifications } from "@/api/inspect/inspectSpecification/useSpecificationsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { RoutingDetailForm } from "@/features/routing/components/form/RoutingDetailForm";
import { MantineReactCommonTable } from "@/features/ui/mantineTable";
import { SpecificationsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";

export const InspectSpecificationsByItemTable = (params: { itemCode: string | undefined }) => {
  const { itemCode } = params;

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 1,
    pageSize: 10,
  });
  // 각 컬럼 sort
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  // 각 컬럼 filter
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  // 전체 검색 (단, serchFields에 필드를 직접 입력해줘야함.)
  const [globalFilter, setGlobalFilter] = useState("");

  // sort를 id 별 string으로 만들어줌
  const sortString = sorting.map(({ id, desc }) => (desc ? `-${id}` : id)).join(",");

  // filter를 id 별 like를 사용하여 단어가 포함되어있는지 확인
  const filterQuery: { [key: string]: { $iLike: string } } = columnFilters.reduce<{
    [key: string]: { $iLike: string };
  }>((acc, filter) => {
    acc[filter.id] = { $iLike: `%${filter.value}%` as string };
    return acc;
  }, {});

  const { data: spectification } = useQuery(
    specifications.get({
      query: {
        $and: [
          { itemCode: itemCode },
          ...Object.keys(filterQuery).map((key) => ({ [key]: filterQuery[key] })),
        ],
      },
      pageSize: pagination.pageSize,
      page: pagination.pageIndex,
      search: globalFilter,
      searchFields: ["itemCode"],
      sort: sortString || undefined,
    }),
  );

  const { openModal } = useModal();

  const rows: SpecificationsGet200ResponseRowsInner[] = spectification?.data.rows ?? [];

  const columns = useMemo<MRT_ColumnDef<SpecificationsGet200ResponseRowsInner>[]>(
    () => [
      {
        // 1
        accessorKey: "itemCode",
        header: "품목코드",
        size: 80,
      },
      {
        accessorKey: "routingCode",
        header: "검사종류",
        enableColumnFilter: false,
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          return (
            <Badge color="blue" radius={2} fw={700} size={"9px"}>
              초중종물 검사
            </Badge>
          );
        },
      },
      {
        accessorKey: "summary",
        header: "검사 기준 요약",
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          const summaryNames = rows.row.original.criteria.map((data: any) => data.name);
          return (
            <>
              {/* {summaryNames.join(", ")} */}
              {summaryNames.length > 1
                ? summaryNames[0] + " 외 " + (summaryNames.length - 1) + "개"
                : summaryNames[0]}
            </>
          );
        },
      },
    ],
    [],
  );

  const renderActions = (row: SpecificationsGet200ResponseRowsInner) => {
    <Flex gap="md">
      <Tooltip label="라우팅 상세정보">
        <ActionIcon
          onClick={() =>
            openModal(<RoutingDetailForm routingCode={row?.routingCode} />, "", "라우팅 상세정보")
          }
        >
          <IconSearch />
        </ActionIcon>
      </Tooltip>
    </Flex>;
  };

  return (
    <MantineReactCommonTable
      data={rows as SpecificationsGet200ResponseRowsInner[]}
      columns={columns}
      totalCount={spectification?.data.total}
      totalPages={spectification?.data.totalPages}
      pagination={pagination} // pagination
      sorting={sorting} // 정렬
      columnFilters={columnFilters} // 컬럼별 검색
      setPagination={setPagination}
      setSorting={setSorting}
      setColumnFilters={setColumnFilters}
      globalFilter={globalFilter} // 전체 검색
      setGlobalFilter={setGlobalFilter}
      // enableRowActions={true} // 액션 가능 여부
      // positionActionsColumn={true} // 액션 위치
      // enableEditing={true} // 수정 가능 여부
      enableTopToolbar={true} // 상단 툴바
      // renderRowActions={({ row }) => (
      //   <Flex gap="md">
      //     <Tooltip label="라우팅 상세정보">
      //       <ActionIcon
      //         onClick={() =>
      //           openModal(
      //             <RoutingDetailForm routingCode={row?.original?.routingCode} />,
      //             "",
      //             "라우팅 상세정보",
      //           )
      //         }
      //       >
      //         <IconSearch />
      //       </ActionIcon>
      //     </Tooltip>
      //   </Flex>
      // )}
    />
  );
};
