import { Card, Flex, Table, Text } from "@mantine/core";

export const WorkStandardList = () => {
  const data = [
    {
      category: "유해화학물질",
      item: "Cd",
      unit: "ppm",
      method: "XRF",
      condition: "Color 시편",
      sampleSize: "1 EA",
      freq: "Lot",
      spec: "80↓",
      inspection: ["●", "", "●"],
    },
    {
      category: "유해화학물질",
      item: "Pb",
      unit: "ppm",
      method: "XRF",
      condition: "Color 시편",
      sampleSize: "1 EA",
      freq: "Lot",
      spec: "100↓",
      inspection: ["●", "", "●"],
    },
    {
      category: "유해화학물질",
      item: "Hg",
      unit: "ppm",
      method: "XRF",
      condition: "Color 시편",
      sampleSize: "1 EA",
      freq: "Lot",
      spec: "100↓",
      inspection: ["●", "", "●"],
    },
    {
      category: "물성검사",
      item: "비중",
      unit: "-",
      method: "ASTM D792",
      condition: "Color 시편",
      sampleSize: "3 EA",
      freq: "Lot",
      spec: "0.915±0.050",
      inspection: ["●", "X", "●"],
    },
    {
      category: "물성검사",
      item: "Izod 강도",
      unit: "kgf.cm/cm",
      method: "ASTM D256",
      condition: "1/8 inch, 23℃",
      sampleSize: "5 EA",
      freq: "Lot",
      spec: "9.0↑",
      inspection: ["●", "", "●"],
    },
    {
      category: "난연",
      item: "난연도",
      unit: "Class",
      method: "UL94",
      condition: "Color 시편",
      sampleSize: "3 EA",
      freq: "Lot",
      spec: "-",
      inspection: ["●", "", "●"],
    },
    {
      category: "외관",
      item: "색상",
      unit: "DE",
      method: "MILOTA색차계",
      condition: "D65, SCI",
      sampleSize: "3 EA",
      freq: "Ton",
      spec: "DE 0.25↓",
      inspection: ["●", "", "●"],
    },
  ];

  return (
    <Card shadow="sm" padding="lg" h="52rem" w="80rem">
      <Flex justify={"space-between"} align={"center"} ta="center">
        <Text weight={700} size="xl" align="center">
          경남케미칼
        </Text>
        <Text weight={700} size="xl" align="center">
          완제품 검사 기준서
        </Text>
        <Table w="20%" withBorder withColumnBorders ta="center">
          <tbody>
            <tr>
              <td style={{ width: "40%" }}>문서번호</td>
              <td></td>
            </tr>
            <tr>
              <td>작성부서</td>
              <td>연구개발부</td>
            </tr>
            <tr>
              <td>개정번호</td>
              <td>REV.0</td>
            </tr>
          </tbody>
        </Table>
      </Flex>
      <Flex w="100%" justify="space-between" direction={"row"} align="center" gap="0.3px" my="md">
        <Flex w="25%" justify="space-between" style={{ border: "1px solid #000" }} ta={"center"}>
          <Text style={{ border: "1px solid #000" }} w="40%">
            품명
          </Text>
          <Text w="60%" style={{ border: "1px solid #000" }}>
            PP
          </Text>
        </Flex>
        <Flex w="25%" justify="space-between" style={{ border: "1px solid #000" }} ta={"center"}>
          <Text style={{ border: "1px solid #000" }} w="40%">
            GRADE
          </Text>
          <Text w="60%" style={{ border: "1px solid #000" }}>
            REP60
          </Text>
        </Flex>
        <Flex w="25%" justify="space-between" style={{ border: "1px solid #000" }} ta={"center"}>
          <Text style={{ border: "1px solid #000" }} w="40%">
            COLOR
          </Text>
          <Text w="60%" style={{ border: "1px solid #000" }}>
            MG
          </Text>
        </Flex>
        <Flex w="25%" justify="space-between" style={{ border: "1px solid #000" }} ta={"center"}>
          <Text style={{ border: "1px solid #000" }} w="40%">
            고객사
          </Text>
          <Text w="60%" style={{ border: "1px solid #000" }}>
            KNC_VINA
          </Text>
        </Flex>
      </Flex>
      <Table striped highlightOnHover withBorder my="md" ta="center" h="50%">
        <thead>
          <tr>
            <th>항목</th>
            <th>단위</th>
            <th>테스트 방법</th>
            <th>테스트 조건</th>
            <th>샘플 크기</th>
            <th>검사 주기</th>
            <th>규격(SPEC)</th>
            <th>초품 검사</th>
            <th>공정 검사</th>
            <th>최종 검사</th>
          </tr>
        </thead>
        <tbody>
          {data.reduce<JSX.Element[]>((acc, row, index) => {
            const isFirstInCategory = index === 0 || data[index - 1].category !== row.category;
            const categoryRowCount = data.filter((item) => item.category === row.category).length;

            acc.push(
              <tr key={index}>
                {isFirstInCategory && <td rowSpan={categoryRowCount}>{row.category}</td>}
                <td>{row.item}</td>
                <td>{row.unit}</td>
                <td>{row.method}</td>
                <td>{row.condition}</td>
                <td>{row.sampleSize}</td>
                <td>{row.freq}</td>
                <td>{row.spec}</td>
                <td>{row.inspection[0]}</td>
                <td>{row.inspection[1]}</td>
                <td>{row.inspection[2]}</td>
              </tr>,
            );

            return acc;
          }, [])}
        </tbody>
      </Table>
      <Flex>
        <Text>
          1. 검사 결과는 완제품 검사 보고서에 기록(색상은 색차검사일보에 Ton단위로 기록)
          <br />
          2. 검사 결과에 대하여 불합격시 Label "부적합" 표기
        </Text>
      </Flex>
      <Flex w="100%" justify={"space-between"} mt="md" gap="md">
        <Table w={"30%"} withBorder withColumnBorders ta="center">
          <tbody>
            <tr>
              <td style={{ width: "40%" }}>품명</td>
              <td>PP</td>
            </tr>
            <tr>
              <td>GRADE</td>
              <td>품명</td>
            </tr>
            <tr>
              <td>COLOR</td>
              <td>MG</td>
            </tr>
            <tr>
              <td>책임자</td>
              <td>연구소장</td>
            </tr>
          </tbody>
        </Table>
        <Table w={"40%"} withBorder withColumnBorders ta="center">
          <tbody>
            <tr>
              <td style={{ width: "40%" }}>제/개정일자</td>
              <td>개정사유</td>
            </tr>
            <tr>
              <td>2025.03.16</td>
              <td>신규</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </Table>
        <Table w={"30%"} withBorder withColumnBorders ta="center">
          <tbody>
            <tr>
              <td style={{ height: "20%" }}>작성</td>
              <td>검토</td>
              <td>승인</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </Table>
      </Flex>
    </Card>
  );
};
